import createPersistedState from "use-persisted-state";

import { AsyncTaskFilters } from "api/types";
import { useSnackbar } from "modulesV2/Snackbar";

export const LOCAL_STORAGE_KEY = "dashboard_asyncTaskFilters";

const useAsyncTaskFiltersState =
  createPersistedState<Partial<AsyncTaskFilters>>(LOCAL_STORAGE_KEY);

export const useAsyncTaskFilters = () => {
  const [asyncTaskFilters, setAsyncTaskFilters] = useAsyncTaskFiltersState({});

  const { showErrorSnack } = useSnackbar();

  // Set or unset resource type, unset skillRequirements
  const setResourceTypeFilter = (
    resourcetype?: AsyncTaskFilters["resourcetype"]
  ) => {
    try {
      // Update resource type filter
      setAsyncTaskFilters((curr) => ({
        ...curr,
        resourcetype,
        skillRequirements: undefined,
      }));
    } catch {
      showErrorSnack("An error occurred while setting task filters");
    }
  };

  // Set skill requirements with resourve type, unset both if no skill requirements
  const setSkillRequirementsFilter = (
    resourcetype: AsyncTaskFilters["resourcetype"],
    skillRequirements: AsyncTaskFilters["skillRequirements"]
  ) => {
    try {
      setAsyncTaskFilters((curr) =>
        skillRequirements.length > 0
          ? { ...curr, resourcetype, skillRequirements }
          : { ...curr, resourcetype: undefined, skillRequirements: undefined }
      );
    } catch {
      showErrorSnack("An error occurred while setting task filters");
    }
  };

  // Set facility ids
  const setFacilityIdsFilter = (
    facilityIds: AsyncTaskFilters["facilityIds"]
  ) => {
    try {
      setAsyncTaskFilters((curr) => ({ ...curr, facilityIds }));
    } catch {
      showErrorSnack("An error occurred while setting task filters");
    }
  };

  return {
    asyncTaskFilters,
    setResourceTypeFilter,
    setSkillRequirementsFilter,
    setFacilityIdsFilter,
  };
};
