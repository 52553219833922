import React, { useState } from "react";

interface ExpandableTexttProps {
  text: string | undefined;
  maxChars: number;
  title?: string;
}

export const ExpandableText: React.FC<ExpandableTexttProps> = ({
  text,
  maxChars,
  title = "",
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  if (!text) return null;

  const shouldShowToggle = text.length > maxChars;
  const displayText = isExpanded
    ? text
    : text.slice(0, maxChars) + (shouldShowToggle ? "..." : "");

  return (
    <div className="text-gray-500 text-xs leading-4">
      {title && <div className="font-semibold mb-1">{title}</div>}
      <div className="text-xs font-normal max-w-lg">
        <span>{`"${displayText}" `}</span>
        {shouldShowToggle && (
          <button
            onClick={toggleIsExpanded}
            type="button"
            className="text-navy-700 leading-4 font-medium"
          >
            {isExpanded ? "See less" : "See more"}
          </button>
        )}
      </div>
    </div>
  );
};
