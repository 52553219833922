import { CallQueueItem, RealtimeTaskType, Role } from "api/types";
import { CallQueueCallCount, CallQueueFilter } from "./types";
import { getElapsedTimeMs } from "utils/date";
import { CALL_QUEUE_UPDATE_DELAY_MS } from "./constants";

/**
 * Group provided call queue items array into filtered and upcoming arrays
 * @param callQueueItems Call queue item array
 * @returns filteredItems: items created after delay value, upcomingItems: items created before delay value
 */
export const groupCallQueueItems = (
  callQueueItems: CallQueueItem[]
): {
  filteredItems: CallQueueItem[];
  upcomingItems: CallQueueItem[];
} => {
  const filteredItems: CallQueueItem[] = [];
  const upcomingItems: CallQueueItem[] = [];

  callQueueItems.forEach((callQueueItem) => {
    const elapsedTimeMs = getElapsedTimeMs(callQueueItem.timeCreated);
    const targetArray =
      elapsedTimeMs >= CALL_QUEUE_UPDATE_DELAY_MS
        ? filteredItems
        : upcomingItems;
    targetArray.push(callQueueItem);
  });

  return { filteredItems, upcomingItems };
};

/**
 * Iterates through upcoming items and returns the lowest remaining time
 * @param upcomingItems call queue items created before delay value
 * @returns time in ms
 */
export const getTimeUntilNextUpcomingItem = (
  upcomingItems: CallQueueItem[]
): number => {
  let timeUntilNextUpcomingItem: number | undefined;

  upcomingItems.forEach((callQueueItem) => {
    const remainingTime =
      CALL_QUEUE_UPDATE_DELAY_MS - getElapsedTimeMs(callQueueItem.timeCreated);

    if (
      typeof timeUntilNextUpcomingItem === "undefined" ||
      remainingTime < timeUntilNextUpcomingItem
    ) {
      timeUntilNextUpcomingItem = remainingTime;
    }
  });

  return timeUntilNextUpcomingItem ?? 0;
};

export const getCallCount = (
  callqueue: CallQueueItem[]
): CallQueueCallCount => {
  const numberCalls: CallQueueCallCount = { counter: 0, phone: 0, all: 0 };

  for (const call of callqueue) {
    if (
      call.type === RealtimeTaskType.CounterActivity ||
      call.type === RealtimeTaskType.ButtonPressed
    ) {
      numberCalls.counter++;
    } else if (call.type === RealtimeTaskType.PhoneCall) {
      numberCalls.phone++;
    }
  }

  numberCalls.all = callqueue.length;

  return numberCalls;
};

export const getInitialCallQueueFilter = (role: Role): CallQueueFilter => {
  switch (role) {
    case Role.CounterOnly:
      return CallQueueFilter.CounterCallsOnly;
    case Role.PhoneOnly:
      return CallQueueFilter.PhoneCallsOnly;
    default:
      return CallQueueFilter.AllCalls;
  }
};

export const getCallQueueWaitingText = (
  filterValue: string,
  callCount: CallQueueCallCount
): string => {
  let numberCalls = 0;

  switch (filterValue) {
    case CallQueueFilter.CounterCallsOnly:
      numberCalls = callCount.counter;
      break;
    case CallQueueFilter.PhoneCallsOnly:
      numberCalls = callCount.phone;
      break;
    default:
      numberCalls = callCount.all;
  }

  if (numberCalls === 0) {
    return `0 ${
      filterValue === CallQueueFilter.CounterCallsOnly ? "Counter" : "Phone"
    } Calls Waiting`;
  }

  if (callCount.all === 100) {
    return "100+ Call Waiting";
  }

  return `${numberCalls} Call${numberCalls === 1 ? "" : "s"} Waiting`;
};
