import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Howl } from "howler";
import { Channel } from "pusher-js";

import {
  ActivityType,
  AsyncTask,
  FacilityActivity,
  RealtimeTaskStatus,
  RealtimeTaskType,
  TaskAssignment,
  MessageTask,
  FeatureName,
  Feature,
  CallQueue,
} from "api/types";
import { useSnackbar } from "components/Snackbar";
import { useDispatch, useSelector, useStore } from "hooks/redux";
import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";
import { fetchAsyncTasksCount } from "services/asyncTasks";
import { refresh } from "services/session";
import {
  fetchCallQueue,
  fetchTaskAssignments,
  maybeAutopassTaskAssignment,
  updateCallQueueAndTopCall,
} from "services/tasks";
import { newUnhandledActivity } from "state/activitiesSlice";
import { agentSelector } from "state/agentSlice";
import { asyncTasksCountSelector } from "state/asyncTasksSlice";
import {
  upsertMessageTaskAlert,
  deleteMessageTaskAlert,
} from "state/dashboardSlice";
import {
  isDialedInSelector,
  tasksSelector,
  upsertTaskAssignment,
} from "state/tasksSlice";
import {
  checkIfAsyncTaskMatchesFilters,
  checkIfAsyncTaskIsAssignedToUser,
  getNewMessagesCount,
  getLatestNewMessage,
} from "utils/asyncTask";
import { checkIfSystemTimeAcceptable } from "utils/date";
import { emptyFn } from "utils/emptyFn";
import { pusher, handlePusherResourceEvent } from "services/Pusher";
import { AUTOPASS_DELAY_MS, AUTOPASS_DELAY_BUFFER_MS } from "utils/task";
import { getConversationCustomerDisplayName } from "utils/conversations";
import { displayName } from "utils/facility";
import {
  callQueueSelector,
  counterCallQueueSelector,
  phoneCallQueueSelector,
  sequenceIdSelector,
} from "state/callQueueSlice";
import { useClarity } from "hooks/clarity";

interface DashboardContextValue {
  loading: boolean;
  activeCalls: TaskAssignment[];
  incomingAsyncTasks: number[];
  asyncTasksCount: number;
  isAsyncTasksEnabled: boolean;
  resetIncomingAsyncTasks: () => void;
  showTimeErrorSnack: (time: string) => void;
}

const initialValue = {
  loading: false,
  activeCalls: [],
  incomingAsyncTasks: [],
  asyncTasksCount: 0,
  isAsyncTasksEnabled: true,
  resetIncomingAsyncTasks: emptyFn,
  showTimeErrorSnack: emptyFn,
};

const DashboardContext = createContext<DashboardContextValue>(initialValue);

interface DashboardProviderProps {
  children: React.ReactNode;
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const bellSound = new Howl({
    src: ["/assets/bell.wav"],
  });
  const smsAlertSound = new Howl({
    src: ["/assets/sms-alert.wav"],
  });

  const [loading, setLoading] = useState(true);
  const [userTaskChannels, setUserTaskChannels] = useState<Channel[]>([]);
  const [incomingAsyncTasks, setIncomingAsyncTasks] = useState<number[]>([]);
  const { asyncTaskFilters } = useAsyncTaskFilters();

  //Call queue info
  const currentCallQueue = useSelector(callQueueSelector);
  const currentPhoneCallQueue = useSelector(phoneCallQueueSelector);
  const currentCounterCallQueue = useSelector(counterCallQueueSelector);
  const currentCallQueueSequenceId = useSelector(sequenceIdSelector);
  const currentCallQueueRef = useRef(currentCallQueue);
  const currentPhoneCallQueueRef = useRef(currentPhoneCallQueue);
  const currentCounterCallQueueRef = useRef(currentCounterCallQueue);
  const currentCallQueueSequenceIdRef = useRef(currentCallQueueSequenceId);

  // Adds task id unless it already exists
  const addIncomingAsyncTasks = (task: AsyncTask) => {
    setIncomingAsyncTasks((curr) =>
      curr.includes(task.id) ? curr : [...curr, task.id]
    );
  };

  const resetIncomingAsyncTasks = () => {
    setIncomingAsyncTasks([]);
  };

  const store = useStore();
  const agent = useSelector(agentSelector);
  const dispatch = useDispatch();
  const {
    showSuccessSnack,
    showInfoSnack,
    showErrorSnack,
    showMessageTaskAlertSnack,
  } = useSnackbar();
  const activeCalls = useSelector(tasksSelector);
  const initialAsyncTasksCount = useSelector(asyncTasksCountSelector);
  const isDialedIn = useSelector(isDialedInSelector);
  const shouldMuteTaskAlert = agent.isSecondaryAlertMuted && isDialedIn;

  const {
    claritySetAgentIdentity,
    claritySetAgentAutoPassTask,
    claritySetAgentCounterAlert,
  } = useClarity();

  useEffect(
    function updateCurrentCallQueueRef() {
      currentCallQueueRef.current = currentCallQueue;
      currentPhoneCallQueueRef.current = currentPhoneCallQueue;
      currentCounterCallQueueRef.current = currentCounterCallQueue;
      currentCallQueueSequenceIdRef.current = currentCallQueueSequenceId;
    },
    [
      currentCallQueue,
      currentPhoneCallQueue,
      currentCounterCallQueue,
      currentCallQueueSequenceId,
    ]
  );

  // Note: In development, the app gets mounted twice and
  // data will be queried twice. This is due to using
  // React.StrictMode in index.tsx. On a production build
  // (npm run build && serve -s build), the app only mounts once.
  useEffect(() => {
    const loadData = async () => {
      try {
        await dispatch(refresh);
        await dispatch(fetchTaskAssignments);
        await dispatch(fetchAsyncTasksCount(asyncTaskFilters));
        await dispatch(fetchCallQueue());
        if (Notification["permission"] !== "denied") {
          Notification.requestPermission();
        }
        showSuccessSnack("Login successful!");
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(
    function subscribeToUserTaskChannel() {
      if (agent.username.length > 0 && agent.taskChannel?.pusherChannelName) {
        const userTaskChannelName = agent.taskChannel.pusherChannelName;
        const channels = pusher.subscribe(userTaskChannelName);
        setUserTaskChannels(channels);

        return () => {
          pusher.unsubscribe(userTaskChannelName);
          setUserTaskChannels([]);
        };
      }
    },
    [agent.username, agent.taskChannel?.pusherChannelName]
  );

  useEffect(
    function bindEventsToUserTaskChannels() {
      const handleCounterActivity = (taskAssignment: TaskAssignment) => {
        dispatch(upsertTaskAssignment(taskAssignment));
        const text = `Incoming ${
          taskAssignment.realtimeTask.type === RealtimeTaskType.PhoneCall
            ? "phone"
            : "counter"
        } call at ${displayName(taskAssignment.realtimeTask.facility)}.`;
        showInfoSnack(text);
        claritySetAgentCounterAlert(agent, taskAssignment);
        if (!shouldMuteTaskAlert) {
          bellSound.play();
          if (!document.hasFocus()) {
            const notif = new Notification("VMOS", {
              body: text,
              icon: "/logo-light.svg",
            });
            notif.onclick = () => {
              notif.close();
              window.parent.focus();
            };
          }
        }

        if (
          [RealtimeTaskStatus.Created, RealtimeTaskStatus.Reassigned].includes(
            taskAssignment.status
          )
        ) {
          setTimeout(() => {
            maybeAutopassTaskAssignment(taskAssignment.id, store, (ta) => {
              claritySetAgentAutoPassTask(agent, ta);
            });
          }, AUTOPASS_DELAY_MS + AUTOPASS_DELAY_BUFFER_MS);
        }
      };

      const handleCounterActivityEvent = (
        facilityActivity: FacilityActivity
      ) => {
        if (facilityActivity.type === ActivityType.CounterButtonPressed) {
          dispatch(newUnhandledActivity(facilityActivity));
        }
      };

      if (userTaskChannels.length > 0) {
        userTaskChannels.forEach((userTaskChannel) => {
          userTaskChannel.bind("counter_activity", (data: unknown) => {
            handlePusherResourceEvent(data, handleCounterActivity);
          });

          userTaskChannel.bind("counter_activity_event", (data: unknown) => {
            handlePusherResourceEvent(data, handleCounterActivityEvent);
          });
        });

        return () => {
          userTaskChannels.forEach((userTaskChannel) => {
            userTaskChannel.unbind("counter_activity");
            userTaskChannel.unbind("counter_activity_event");
          });
        };
      }
    },
    [userTaskChannels, shouldMuteTaskAlert]
  );

  useEffect(() => {
    if (agent.id > 0 && agent.email.length) {
      claritySetAgentIdentity(agent);
    }
  }, [agent]);

  useEffect(
    function subscribeToAsyncTaskCreated() {
      const pushChannelPrefix = agent.organization?.pushChannelPrefix ?? "";
      if (!pushChannelPrefix) {
        return;
      }
      const asyncTaskChannelNames = [
        "LeadFollowUpTask_created",
        "MissedCallFollowUpTask_created",
        "MessageTask_created",
      ].map((name) => pushChannelPrefix + name);

      // Subscribe to channels
      const asyncTaskChannelsArray = asyncTaskChannelNames.map((name) =>
        pusher.subscribe(name)
      );

      const handleAsyncTaskCreated = (asyncTask: AsyncTask) => {
        // Only increment incoming task if skills match filters
        const matchesFilters = checkIfAsyncTaskMatchesFilters(
          asyncTask,
          asyncTaskFilters
        );

        if (matchesFilters) {
          addIncomingAsyncTasks(asyncTask);
          showInfoSnack(`New task at ${displayName(asyncTask.facility)}`);
        }
      };

      asyncTaskChannelsArray.forEach((asyncTaskChannels) => {
        // Bind model_created event handlers
        asyncTaskChannels.forEach((channel) => {
          channel.bind("model_created", (event: unknown) =>
            handlePusherResourceEvent(event, handleAsyncTaskCreated)
          );
        });
      });

      return () => {
        asyncTaskChannelsArray.forEach((asyncTaskChannels) => {
          // Unbind model_created event handlers
          asyncTaskChannels.forEach((channel) => {
            channel.unbind("model_created");
          });
        });

        // Unsubscribe from channels
        asyncTaskChannelNames.forEach((name) => {
          pusher.unsubscribe(name);
        });
      };
    },
    [agent.organization?.pushChannelPrefix, asyncTaskFilters]
  );

  useEffect(
    function subscribeToMessageTaskUpdated() {
      const pushChannelPrefix = agent.organization?.pushChannelPrefix ?? "";
      if (!pushChannelPrefix) {
        return;
      }
      const channelName = `${pushChannelPrefix}MessageTask_updated`;
      const channels = pusher.subscribe(channelName);

      const handleMessageTaskUpdated = (messageTask: MessageTask) => {
        const messageTaskId = messageTask.id;
        const isAssigned = checkIfAsyncTaskIsAssignedToUser(messageTask, agent);
        const latestNewMessage = getLatestNewMessage(messageTask);

        if (isAssigned && latestNewMessage) {
          smsAlertSound.play();
          const alert = {
            time: latestNewMessage.time,
            newMessageCount: getNewMessagesCount(messageTask),
            customer: getConversationCustomerDisplayName(
              messageTask.conversation
            ),
          };
          dispatch(upsertMessageTaskAlert({ messageTaskId, alert }));
          showMessageTaskAlertSnack(messageTaskId);
        } else {
          dispatch(deleteMessageTaskAlert(messageTaskId));
        }
      };

      // Bind event handler
      channels.forEach((channel) => {
        channel.bind("model_updated", (event: unknown) =>
          handlePusherResourceEvent(event, handleMessageTaskUpdated)
        );
      });

      return () => {
        // Unbind event handler
        channels.forEach((channel) => {
          channel.unbind("model_updated");
        });
        // Unsubscribe from channel
        pusher.unsubscribe(channelName);
      };
    },
    [agent.organization?.pushChannelPrefix, agent.id]
  );

  useEffect(
    function subscribeToCallQueue() {
      const pushChannelPrefix = agent.organization?.pushChannelPrefix ?? "";
      if (!pushChannelPrefix) {
        return;
      }
      const channelName = `${pushChannelPrefix}realtime_tasks_queue_updated`;
      const channels = pusher.subscribe(channelName);

      const handleCallQueueUpdated = async (callQueue: CallQueue) => {
        if (
          typeof callQueue.queueSequenceId === "number" &&
          currentCallQueueSequenceIdRef.current &&
          callQueue.queueSequenceId < currentCallQueueSequenceIdRef.current
        )
          return;

        await updateCallQueueAndTopCall(
          dispatch,
          callQueue.queue,
          callQueue.queueSequenceId
        );
      };

      // Bind event handler
      channels.forEach((channel) => {
        channel.bind("model_updated", (event: unknown) =>
          handlePusherResourceEvent(event, handleCallQueueUpdated)
        );
      });

      return () => {
        // Unbind event handler
        channels.forEach((channel) => {
          channel.unbind("model_updated");
        });
        // Unsubscribe from channel
        pusher.unsubscribe(channelName);
      };
    },
    [agent.organization?.pushChannelPrefix]
  );

  const [timeErrorShown, setTimeErrorShown] = useState(false);
  const showTimeErrorSnack = useCallback(
    (time: string) => {
      // Early exit if already shown this session or
      // if time diff is within acceptable range.
      if (timeErrorShown || checkIfSystemTimeAcceptable(time)) {
        return;
      }

      const timeMs = new Date(time).getTime();
      const systemMs = new Date().getTime();
      const timeDiff = timeMs - systemMs;
      const timeDiffDisplay = `${Math.floor(timeDiff / 1000)} seconds`;
      const errorMessage = `Please check that your system time is set correctly. (Behind server time by at least ${timeDiffDisplay})`;

      showErrorSnack(errorMessage, null);
      setTimeErrorShown(true);
    },
    [
      timeErrorShown,
      setTimeErrorShown,
      showErrorSnack,
      checkIfSystemTimeAcceptable,
    ]
  );

  // Total = initial (from fetch) + incoming (from model_created event)
  const asyncTasksCount = initialAsyncTasksCount + incomingAsyncTasks.length;

  const isAsyncTasksEnabled =
    !!agent.organization &&
    agent.organization.features.reduce(
      (acc: boolean, feature: Feature): boolean => {
        return (
          acc ||
          [
            FeatureName.CallCenter,
            FeatureName.Messages,
            FeatureName.LeadFollowUp,
          ].includes(feature.name)
        );
      },
      false
    );

  return (
    <DashboardContext.Provider
      value={{
        loading,
        activeCalls,
        incomingAsyncTasks,
        asyncTasksCount,
        isAsyncTasksEnabled,
        resetIncomingAsyncTasks,
        showTimeErrorSnack,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
