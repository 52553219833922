import React, { useState } from "react";
import { User, TaskStatus } from "api/types";
import { userStatus, displayName } from "utils/user";
import { UserStatusBadge } from "componentsV2/Badges";
import { Avatar } from "componentsV2/Avatar";
import { SkillsModal } from "../SkillsModal";
import { useVirtualManagers } from "../../contexts/VirtualManagersContext";

export const testIds = {
  row: "vm-row",
};

type Props = {
  virtualManager: User;
};

export const VirtualManagerRow = ({ virtualManager }: Props) => {
  const [skillsModalOpen, setSkillsModalOpen] = useState(false);
  const { userActiveAssignments } = useVirtualManagers();
  const availability = userStatus(virtualManager);
  const activeAssignments = userActiveAssignments[virtualManager.id];
  const userCallCount = activeAssignments?.length;

  const callTypeName = (type: TaskStatus) => {
    switch (type) {
      case TaskStatus.OnCounterCall:
        return "Counter";
      case TaskStatus.OnPhoneCall:
        return "Phone In";
      case TaskStatus.OnOutboundPhoneCall:
        return "Phone Out";
      default:
        return "Busy";
    }
  };

  const openSkillsModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSkillsModalOpen((curr) => !curr);
  };

  return (
    <tr key={virtualManager.id} data-testid={testIds.row}>
      <td className="h-px py-0 sticky left-0 bg-white max-w-xs overflow-hidden divide-gray-300 divide-x-2">
        <div
          className="h-full border-r border-gray-200 flex flex-row py-4 px-6 text-sm font-medium leading-5 text-gray-900"
          onClick={openSkillsModal}
        >
          <Avatar text={displayName(virtualManager)} />
          <div className="px-4 flex flex-col justify-center">
            <div className="whitespace-nowrap text-sm font-medium leading-5 text-gray-900">
              <button type="button" onClick={openSkillsModal}>
                {displayName(virtualManager)}
              </button>
            </div>
            <div className="whitespace-nowrap text-xs leading-4 font-normal text-gray-500">
              {virtualManager.email}
            </div>
          </div>
        </div>
        <SkillsModal
          active={skillsModalOpen}
          handleClose={() => setSkillsModalOpen(false)}
          virtualManager={virtualManager}
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <UserStatusBadge userStatus={availability} callCount={userCallCount} />
      </td>
      {activeAssignments?.map((rttaInfo, index) => (
        <td key={index}>
          <div className="whitespace-nowrap pt-4 px-3 text-sm font-normal leading-5 text-gray-900">
            {rttaInfo.facilityTitle}
          </div>
          <div className="whitespace-nowrap pb-4 px-3 text-xs leading-5 font-normal text-gray-500">
            {callTypeName(rttaInfo.type)}
          </div>
        </td>
      ))}
    </tr>
  );
};
