import React from "react";

interface AwayBannerProps {
  text: string;
}

export const AwayBanner: React.FC<AwayBannerProps> = ({ text }) => (
  <div className="h-12 p-3.5 sticky top-0 z-20 bg-red-800 text-white text-sm leading-5 text-center">
    {text}
  </div>
);
