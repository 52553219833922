import React from "react";
import cn from "classnames";

import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";
import { pillClassNames } from "./constants";

export const FilterReset: React.FC = () => {
  const { asyncTaskFilters, setResourceTypeFilter } = useAsyncTaskFilters();

  const noFiltersSelected = !asyncTaskFilters.resourcetype;

  const resetAsyncTaskFilters = () => setResourceTypeFilter();

  return (
    <button
      type="button"
      onClick={resetAsyncTaskFilters}
      role="switch"
      aria-checked={noFiltersSelected}
      className={cn(
        pillClassNames.shared,
        noFiltersSelected ? pillClassNames.primary : pillClassNames.secondary
      )}
    >
      All tasks
    </button>
  );
};
