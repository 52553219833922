import React from "react";
import { Popover } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { PanelForm } from "./PanelForm";
import { pillClassNames } from "../constants";
import { FilterGroupItem, HandleUpdateItems } from "../types";

interface FilterGroupProps {
  label: string;
  items: FilterGroupItem[];
  activeItems: string[];
  handleUpdateItems: HandleUpdateItems;
  handleReset?: () => void;
  buttonIsActive: boolean;
  panelClassName?: string;
  showFilterInput?: boolean;
}

export const FilterGroup: React.FC<FilterGroupProps> = ({
  label,
  items,
  activeItems,
  handleUpdateItems,
  handleReset,
  buttonIsActive,
  panelClassName,
  showFilterInput,
}) => {
  const buttonLabel =
    activeItems.length > 0 ? `${label} (${activeItems.length})` : label;

  const showResetButton = !!handleReset;
  const handleClickReset: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    if (handleReset) {
      handleReset();
    }
  };

  return (
    <Popover className="relative">
      <Popover.Button
        type="button"
        role="switch"
        aria-checked={buttonIsActive}
        aria-haspopup="listbox"
        className={({ open }) =>
          cn(pillClassNames.shared, {
            [pillClassNames.ring]: open,
            [pillClassNames.primary]: buttonIsActive,
            [pillClassNames.secondary]: !buttonIsActive,
            [pillClassNames.hasReset]: showResetButton,
          })
        }
      >
        {buttonLabel}
      </Popover.Button>
      {showResetButton && (
        <button
          onClick={handleClickReset}
          type="button"
          aria-label="Reset items"
          className={cn(
            "absolute top-1/2 -translate-y-1/2 right-3",
            buttonIsActive ? "text-white" : "text-navy-900"
          )}
        >
          <XMarkIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      )}
      <Popover.Panel
        className={cn("absolute top-full translate-y-1.5 z-10", panelClassName)}
      >
        {({ close }) => (
          <PanelForm
            closePanel={close}
            items={items}
            activeItems={activeItems}
            handleUpdateItems={handleUpdateItems}
            showFilterInput={showFilterInput}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};
