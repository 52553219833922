import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useSelector } from "hooks/redux";
import { agentSelector } from "state/agentSlice";
import { createFacilityTenant, searchFacilityTenant } from "api/facilities";
import {
  CallScriptsPrefill,
  Facility,
  SourceChannel,
  Tenant,
  FeatureName,
  Feature,
  OrgResolution,
  ResolutionCategory,
} from "api/types";
import { useSnackbar } from "components/Snackbar";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
import { checkIfFacilityHasMorannonId } from "utils/facility";
import { LookupTenantValues, CreateNewLeadValues, Script } from "../types";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { resolutions } from "../constants";

interface ContextValueType {
  activeTabIndex: number;
  leadSelectOpen: boolean;
  documentModalOpen: boolean;
  hasCreatedNewLead: boolean;
  possibleLeads: Tenant[];
  tenant?: Tenant;
  facility?: Facility;
  facilityId?: number;
  prefill: CallScriptsPrefill;
  completeResolutionDisabled: boolean;
  isSubmittingCreateNewLead: boolean;
  isLookingUpTenant: boolean;
  isPmsFeatureEnabled: boolean;
  isSendDocsEnabled: boolean;
  isUsingOrgResolution: boolean;
  resolutionOptions: Record<Script, { value: string; label: string }[]>;
  createNewLead: (values: CreateNewLeadValues) => Promise<void>;
  redirectScript: () => void;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  setLeadSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDocumentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleLookupTenant: (values: LookupTenantValues) => Promise<void>;
  handleCreateNewLead: (values: CreateNewLeadValues) => Promise<void>;
  handleSelectLead: (tenant: Tenant) => Promise<void>;
  handleUpdateFacility: (facilityId: number) => Promise<void>;
  handleCompleteResolution: (
    isUsingOrgResolution: boolean,
    resolution: string
  ) => Promise<void>;
}

const initialValue = {
  activeTabIndex: 0,
  leadSelectOpen: false,
  documentModalOpen: false,
  hasCreatedNewLead: false,
  possibleLeads: [],
  tenant: undefined,
  facility: undefined,
  facilityId: 0,
  prefill: {},
  completeResolutionDisabled: false,
  isSubmittingCreateNewLead: false,
  isLookingUpTenant: false,
  isPmsFeatureEnabled: false,
  isSendDocsEnabled: false,
  isUsingOrgResolution: false,
  resolutionOptions: resolutions,
  createNewLead: asyncEmptyFn,
  redirectScript: emptyFn,
  setActiveTabIndex: emptyFn,
  setLeadSelectOpen: emptyFn,
  setDocumentModalOpen: emptyFn,
  handleLookupTenant: asyncEmptyFn,
  handleCreateNewLead: asyncEmptyFn,
  handleSelectLead: asyncEmptyFn,
  handleUpdateFacility: asyncEmptyFn,
  handleCompleteResolution: asyncEmptyFn,
};

const CallScriptsContext = createContext<ContextValueType>(initialValue);

export interface ContextProviderProps {
  children: React.ReactNode;
  loading: boolean;
  handleCompleteResolution: (
    isUsingOrgResolution: boolean,
    resolution: string
  ) => Promise<void>;
  handleSelectLead: (tenant: Tenant) => Promise<void>;
  handleUpdateFacility: (facilityId: number) => Promise<void>;
  facility?: Facility;
  tenant?: Tenant;
  prefill: CallScriptsPrefill;
  source: SourceChannel;
}

export const CallScriptsProvider: React.FC<ContextProviderProps> = ({
  children,
  loading,
  handleCompleteResolution,
  handleSelectLead,
  handleUpdateFacility,
  facility,
  tenant,
  prefill,
  source,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [leadSelectOpen, setLeadSelectOpen] = useState(false);
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [hasCreatedNewLead, setHasCreatedNewLead] = useState(false);
  const [possibleLeads, setPossibleLeads] = useState<Tenant[]>([]);
  const [isSubmittingCreateNewLead, setIsSubmittingCreateNewLead] =
    useState(false);
  const [isLookingUpTenant, setIsLookingUpTenant] = useState(false);

  const agent = useSelector(agentSelector);
  const isPmsFeatureEnabled =
    !!agent.organization &&
    agent.organization.features
      .map((feature: Feature) => feature.name)
      .includes(FeatureName.PmsIntegration);

  const facilityHasMorannonId = checkIfFacilityHasMorannonId(facility);
  const isSendDocsEnabled =
    process.env.REACT_APP_FF_SEND_DOCS === "true" && facilityHasMorannonId;

  const { isUsingOrgResolution, resolutionOptions } = useMemo(() => {
    const isUsingOrgResolution: boolean = !!(
      agent?.organization?.resolutions && agent.organization.resolutions.length
    );
    let resolutionOptions = resolutions;
    if (isUsingOrgResolution) {
      resolutionOptions = {
        [Script.NEW_LEAD]: [],
        [Script.LEAD]: [],
        [Script.TENANT]: [],
        [Script.VENDOR]: [],
        [Script.OTHER]: [],
      };

      const resolutions = agent?.organization?.resolutions
        ? agent.organization.resolutions
        : [];
      resolutions.forEach((resolution: OrgResolution) => {
        let script;
        switch (resolution.category) {
          case ResolutionCategory.NewLead:
            script = Script.NEW_LEAD;
            break;
          case ResolutionCategory.ExistingLead:
            script = Script.LEAD;
            break;
          case ResolutionCategory.Tenant:
            script = Script.TENANT;
            break;
          case ResolutionCategory.Vendor:
            script = Script.VENDOR;
            break;
          default:
            script = Script.OTHER;
        }

        resolutionOptions[script].push({
          value: `${resolution.category} ${resolution.value}`,
          label: resolution.value,
        });
      });
    }

    return {
      isUsingOrgResolution: isUsingOrgResolution,
      resolutionOptions: resolutionOptions,
    };
  }, [agent.organization?.resolutions]);

  const { showErrorSnack } = useSnackbar();

  const redirectScript = () => {
    if (tenant) {
      setActiveTabIndex(
        tenant.hasMovedIn || tenant.isActive
          ? 2 // "Tenant"
          : 1 // "Existing lead"
      );
    } else {
      setActiveTabIndex(
        0 // "New lead"
      );
    }
  };

  // Redirect script initially and when facility changes
  useEffect(
    function redirectScriptWhenFacilityChanges() {
      redirectScript();
    },
    [facility?.id]
  );

  const completeResolutionDisabled = loading;

  const searchTenants = async (phone: string) => {
    if (!facility) {
      throw new Error("Facility must be selected");
    }
    const res = await searchFacilityTenant(facility.url, phone);
    return res.data;
  };

  const createNewLead = async ({
    firstName,
    lastName,
    email,
    phoneNumber,
    marketingSource,
  }: CreateNewLeadValues) => {
    if (!facility) {
      throw new Error("Facility must be selected");
    }

    try {
      // TODO: support date, notes in Tenant type
      const res = await createFacilityTenant(facility.url, {
        firstName,
        lastName,
        email,
        source,
        marketingSource,
        phoneNumbers: [
          {
            phoneNumber: `+1${phoneNumber.replace(/[\s-()]/g, "")}`,
            isPrimary: true,
          },
        ],
      });

      await handleSelectLead(res.data);
      setHasCreatedNewLead(true);
    } catch (e) {
      showErrorSnack();
    }
  };

  const handleLookupTenant = async ({ phoneNumber }: LookupTenantValues) => {
    try {
      setIsLookingUpTenant(true);
      const tenants = await searchTenants(phoneNumber);
      setPossibleLeads(tenants);

      if (tenants.length === 1) {
        await handleSelectLead(tenants[0]);
      } else if (tenants.length > 1) {
        setLeadSelectOpen(true);
      } else {
        showErrorSnack("Tenant not found!");
      }
    } catch (e) {
      showErrorSnack();
    } finally {
      setIsLookingUpTenant(false);
    }
  };

  const handleCreateNewLead = async (values: CreateNewLeadValues) => {
    if (isSubmittingCreateNewLead) return;

    try {
      setIsSubmittingCreateNewLead(true);
      const tenants = await searchTenants(values.phoneNumber);
      setPossibleLeads(tenants);

      if (tenants.length) {
        setLeadSelectOpen(true);
      } else {
        await createNewLead(values);
      }
    } catch (e) {
      showErrorSnack();
    } finally {
      setIsSubmittingCreateNewLead(false);
    }
  };

  return (
    <CallScriptsContext.Provider
      value={{
        activeTabIndex,
        leadSelectOpen,
        documentModalOpen,
        hasCreatedNewLead,
        possibleLeads,
        facility,
        facilityId: facility?.id,
        tenant,
        prefill,
        completeResolutionDisabled,
        isSubmittingCreateNewLead,
        isLookingUpTenant,
        isPmsFeatureEnabled,
        isSendDocsEnabled,
        isUsingOrgResolution,
        resolutionOptions,
        createNewLead,
        redirectScript,
        setActiveTabIndex,
        setLeadSelectOpen,
        setDocumentModalOpen,
        handleLookupTenant,
        handleCreateNewLead,
        handleSelectLead,
        handleUpdateFacility,
        handleCompleteResolution,
      }}
    >
      {children}
    </CallScriptsContext.Provider>
  );
};

export const useCallScripts = () => useContext(CallScriptsContext);

export const MockCallScriptsProvider = createMockContextProvider(
  CallScriptsContext,
  initialValue
);
