import React from "react";
import { AsyncTaskResourceType } from "api/types";

export type FormSubmitHandler = React.FormEventHandler<HTMLFormElement>;
export type CheckboxChangeHandler = React.ChangeEventHandler<HTMLInputElement>;
export type HandleUpdateItems = (items: string[]) => void;

export enum FilterType {
  Toggle = "Toggle",
  Group = "Group",
}

type FilterBaseType = {
  label: string;
  resourcetype: AsyncTaskResourceType;
};

export interface FilterToggleType extends FilterBaseType {
  type: FilterType.Toggle;
}

export type FilterGroupItem = { label: string; value: string };

export interface FilterGroupType extends FilterBaseType {
  type: FilterType.Group;
  skillRequirements: FilterGroupItem[];
}

export type Filter = FilterToggleType | FilterGroupType;
