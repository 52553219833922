import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector, useStore } from "hooks/redux";
import { addUserToConversation } from "api/conversations";
import { updateRealtimeTask, updateTaskAssignment } from "api/tasks";
import {
  CallScriptsPrefill,
  CallStatus,
  Conversation,
  CustomerInteractionResolution,
  Facility,
  FacilityActivity,
  RealtimeTaskStatus,
  RealtimeTaskType,
  SourceChannel,
  Status,
  TaskAssignment,
  TaskStatus,
  Tenant,
  User,
} from "api/types";
import { useSnackbar } from "components/Snackbar";
import { InfoCardProps, InfoNodeType } from "componentsV2/InfoCard";
import { useTwilio } from "modules/Twilio";
import { useWebEx } from "modules/WebEx";
import { connectToVmosCall, endPhoneCall } from "services/connectCalls";
import {
  getConversations,
  handleOpenConversationException,
} from "services/conversations";
import { fetchVirtualManagers } from "services/session";
import {
  dismissTaskAssignment,
  fetchAllTasks,
  passTaskAssignment,
  reassignTaskAssignment,
} from "services/tasks";
import {
  handledActivityAtFacilityLocation,
  unhandledActivitiesAtFacilityLocationsSelector,
} from "state/activitiesSlice";
import { agentSelector } from "state/agentSlice";
import {
  allTasksSelector,
  taskAssignmentsLocalTimeSelector,
  removeByTaskAssignmentId,
  removeByTaskAssignmentIdFromAllTasks,
  tasksLocalStateSelector,
  updateTaskAssignmentLocalState,
  updateTaskAssignmentNextState,
  upsertTaskAssignment,
  tasksNextStateSelector,
} from "state/tasksSlice";
import { virtualManagersSelector } from "state/virtualManagersSlice";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
import { createMockFacility } from "utils/mocks/facilities";
import { formatE164ToDisplay } from "utils/phoneNumber";
import {
  getCallType,
  CallType,
  getCallScriptsPrefill,
  getTaskAssignmentPhone,
} from "utils/task";
import { userStatus, getFirstName } from "utils/user";
import {
  getTaskTypeInfo,
  getFacilityStatusInfo,
  getTenantInfo,
} from "../utils";
import { acceptCall } from "services/tasks";
import { displayName } from "utils/facility";
import { useClarity } from "hooks/clarity";

type TasksCount = {
  [TaskStatus.OnPhoneCall]: number;
  [TaskStatus.OnCounterCall]: number;
};

type UserWithInfo = {
  user: User;
  status: Status;
  tasksCount: TasksCount;
};

interface CallCardContextValue {
  taskId: number;
  taskTimeReceived: string;
  loading: boolean;
  reassignModalOpen: boolean;
  reassignModalLoading: boolean;
  facilityInfoModalOpen: boolean;
  isIncomingCall: boolean;
  isCounterCallWithUnhandledActivity: boolean;
  isDialedInCall: boolean;
  isTwilioCall: boolean;
  isPhoneCall: boolean;
  callActionDisabled: boolean;
  dialInDisabled: boolean;
  hangUpDisabled: boolean;
  virtualManagersOnline: UserWithInfo[];
  unhandledFacilityActivity?: FacilityActivity;
  handleFacilityActivity: () => void;
  setReassignModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFacilityInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAcceptCall: () => Promise<void>;
  handleDismissCall: () => Promise<void>;
  handlePassCall: () => Promise<void>;
  handlePutOnHold: () => Promise<void>;
  handleDialIn: () => Promise<void>;
  handleHangUp: () => Promise<void>;
  handleOpenReassignModal: () => void;
  handleReassignCall: (userId: string) => Promise<void>;
  handleCompleteResolution: (
    isUsingOrgResolution: boolean,
    resolution: string
  ) => Promise<void>;
  handleSelectLead: (tenant: Tenant) => Promise<void>;
  handleUpdateFacility: (facilityId: number) => Promise<void>;
  tenant?: Tenant;
  facility?: Facility;
  callScriptsPrefill: CallScriptsPrefill;
  source: SourceChannel;
  infoCardProps: Pick<InfoCardProps, "title" | "info" | "Icon">;
  conversations?: Conversation[];
  conversationModalOpen: boolean;
  isJoiningConversation: boolean;
  handleOpenConversation: () => Promise<void>;
  handleCloseConversation: () => void;
}

const initialValue = {
  taskId: 0,
  taskTimeReceived: "2023-01-01T12:00:00.000Z",
  loading: false,
  reassignModalOpen: false,
  reassignModalLoading: false,
  facilityInfoModalOpen: false,
  isIncomingCall: false,
  isCounterCallWithUnhandledActivity: false,
  isDialedInCall: false,
  isTwilioCall: false,
  isPhoneCall: false,
  callActionDisabled: false,
  dialInDisabled: false,
  hangUpDisabled: false,
  virtualManagersOnline: [],
  unhandledFacilityActivity: undefined,
  handleFacilityActivity: emptyFn,
  setReassignModalOpen: emptyFn,
  setFacilityInfoModalOpen: emptyFn,
  handleAcceptCall: asyncEmptyFn,
  handleDismissCall: asyncEmptyFn,
  handlePassCall: asyncEmptyFn,
  handlePutOnHold: asyncEmptyFn,
  handleDialIn: asyncEmptyFn,
  handleHangUp: asyncEmptyFn,
  handleOpenReassignModal: emptyFn,
  handleReassignCall: asyncEmptyFn,
  handleCompleteResolution: asyncEmptyFn,
  handleSelectLead: asyncEmptyFn,
  handleUpdateFacility: asyncEmptyFn,
  tenant: undefined,
  facility: createMockFacility(),
  callScriptsPrefill: {},
  source: SourceChannel.Phone,
  infoCardProps: {
    title: "",
    info: [],
    Icon: () => <div />,
  },
  conversationModalOpen: false,
  isJoiningConversation: false,
  handleOpenConversation: asyncEmptyFn,
  handleCloseConversation: emptyFn,
};

const CallCardContext = createContext<CallCardContextValue>(initialValue);

interface ContextProviderProps {
  children: React.ReactNode;
  task: TaskAssignment;
}

export const CallCardProvider: React.FC<ContextProviderProps> = ({
  children,
  task,
}) => {
  const [loading, setLoading] = useState(false);
  const [timeAcceptedLocal, setTimeAcceptedLocal] = useState<
    string | undefined
  >();
  const [reassignModalOpen, setReassignModalOpen] = useState(false);
  const [reassignModalLoading, setReassignModalLoading] = useState(false);
  const [facilityInfoModalOpen, setFacilityInfoModalOpen] = useState(false);

  const [conversations, setConversations] = useState<
    Conversation[] | undefined
  >();
  const [conversationModalOpen, setConversationModalOpen] = useState(false);
  const [isJoiningConversation, setIsJoiningConversation] = useState(false);

  const store = useStore();
  const dispatch = useDispatch();
  const {
    dialedInTaskAssignmentId,
    dialedInAsyncTaskId,
    dialedInPhoneNumber,
    webexActiveCallId,
  } = useSelector(tasksLocalStateSelector);
  const { nextTaskAssignment } = useSelector(tasksNextStateSelector);
  const unhandledActivitiesAtFacilityLocations = useSelector(
    unhandledActivitiesAtFacilityLocationsSelector
  );
  const currentAgent = useSelector(agentSelector);
  const virtualManagers = useSelector(virtualManagersSelector);
  const allTasks = useSelector(allTasksSelector);
  const taskAssignmentsLocalTime = useSelector(
    taskAssignmentsLocalTimeSelector
  );
  const taskTimeReceived =
    taskAssignmentsLocalTime[task.id]?.timeReceived ?? new Date().toISOString();

  const virtualManagersOnline = useMemo(() => {
    const initialTasksCount = {
      [TaskStatus.OnCounterCall]: 0,
      [TaskStatus.OnPhoneCall]: 0,
    };

    // Filters offline users and current user
    const vmsOnline = virtualManagers.filter(
      (user) =>
        userStatus(user) !== Status.Offline && user.id !== currentAgent.id
    );
    const vmsOnlineIds = vmsOnline.map((m) => m.id);

    const vmsTasksCounts = allTasks.reduce(
      (map: Record<string, TasksCount>, t) => {
        const assigneeId = t.assignee.id;
        if (!vmsOnlineIds.includes(assigneeId)) {
          return map;
        }

        // Initialize assignee record and task type counts
        if (map[assigneeId] === undefined) {
          map[assigneeId] = { ...initialTasksCount };
        }

        switch (t.realtimeTask.type) {
          case RealtimeTaskType.ButtonPressed:
          case RealtimeTaskType.CounterActivity:
            map[assigneeId][TaskStatus.OnCounterCall]++;
            break;
          case RealtimeTaskType.PhoneCall:
            map[assigneeId][TaskStatus.OnPhoneCall]++;
            break;
          default:
        }

        return map;
      },
      {}
    );

    return vmsOnline.map((user) => ({
      user,
      status: userStatus(user),
      tasksCount: vmsTasksCounts[user.id] ?? { ...initialTasksCount },
    }));
  }, [allTasks, virtualManagers]);

  const snacks = useSnackbar();
  const { disconnectPhoneCall } = useTwilio();
  const { disconnectFromCall, isAuthenticated, currentDevice } = useWebEx();

  const unhandledFacilityActivity: FacilityActivity | undefined =
    unhandledActivitiesAtFacilityLocations[
      task.realtimeTask.facilityLocationId
    ];

  const handleFacilityActivity = () => {
    dispatch(
      handledActivityAtFacilityLocation(task.realtimeTask.facilityLocationId)
    );
  };

  const { tenant, facility } = task.realtimeTask;

  const isIncomingCall = task.status === RealtimeTaskStatus.Created;
  const isCounterCall = task.realtimeTask.type !== RealtimeTaskType.PhoneCall;
  const isCounterCallWithUnhandledActivity =
    isCounterCall && !!unhandledFacilityActivity;

  const isDialedInCall = task.id === dialedInTaskAssignmentId;
  const isNextCall = task.id === nextTaskAssignment?.id;
  const isPhoneCall = task.realtimeTask.phoneCallId != null;
  const callEnded = task.realtimeTask?.phoneCall?.status === CallStatus.Ended;
  const callType = getCallType(task);
  const videoCallDisabled =
    callType === CallType.WebEx && (!isAuthenticated || !currentDevice);
  const isTwilioCall = callType === CallType.Twilio;
  const source =
    task.realtimeTask.type == RealtimeTaskType.PhoneCall
      ? SourceChannel.Phone
      : SourceChannel.WalkIn;

  const {
    claritySetAgentDismissTask,
    claritySetAgentPassTask,
    claritySetAgentAcceptTask,
  } = useClarity();

  const queueVmosCall = async () => {
    // Phone calls do not disconnect instantly.
    // And making a phone call while another one is in progress will fail
    // Instead queue the call in Redux tasks->nextState.
    // This call will be popped off in connectCalls->call.on("disconnect")
    dispatch(
      updateTaskAssignmentNextState({
        nextTaskAssignment: task,
      })
    );
  };

  const endWebexCall = async () => {
    if (!webexActiveCallId) {
      throw new Error("No active WebEx CallId.");
    }
    await disconnectFromCall(webexActiveCallId);
  };

  const reloadVMs = async () => {
    setReassignModalLoading(true);
    try {
      await Promise.all([
        dispatch(fetchVirtualManagers),
        dispatch(fetchAllTasks),
      ]);
    } catch {
      snacks.showErrorSnack("An error occurred while reloading VMs.");
    } finally {
      setReassignModalLoading(false);
    }
  };

  useEffect(() => {
    /**
     * NOTE: Behavior from modules/Calls/components/ReassignModal.tsx line 150
     * Should VMs be reloaded whenever reassign modal is opened?
     */
    reloadVMs();
  }, []);

  useEffect(
    function clearTimeAcceptedLocal() {
      setTimeAcceptedLocal(undefined);
    },
    [task.id, setTimeAcceptedLocal]
  );

  useEffect(
    function clearConversation() {
      // Cached conversation should be cleared when tenant or facility are updated
      setConversations(undefined);
    },
    [tenant?.id, facility?.id]
  );

  /**
   * Incoming call actions
   */
  const handleAcceptCall = async () => {
    try {
      claritySetAgentAcceptTask(currentAgent, task);
      await acceptCall(task, store, snacks);
      setTimeAcceptedLocal(new Date().toISOString());
    } catch (e) {
      if (e instanceof Error) {
        snacks.showErrorSnack(e.message);
      } else {
        snacks.showErrorSnack("An error occurred while accepting.");
      }
    }
  };

  // TODO: update for compatibility with webex/counter calls
  const handleDismissCall = async () => {
    try {
      claritySetAgentDismissTask(currentAgent, task);
      await dispatch(dismissTaskAssignment(task.id));
    } catch {
      snacks.showErrorSnack("An error occurred while dismissing.");
    }
  };

  const handlePassCall = async () => {
    try {
      claritySetAgentPassTask(currentAgent, task);
      await dispatch(passTaskAssignment(task.id));
    } catch {
      snacks.showErrorSnack("An error occurred while passing.");
    }
  };

  /**
   * Accepted call action disabled statuses
   */
  const callActionDisabled = loading || callEnded;
  const dialInDisabled = videoCallDisabled || isNextCall || callActionDisabled;
  const hangUpDisabled = !isDialedInCall || callActionDisabled;

  /**
   * Accepted call actions
   */
  const handleDialIn = async () => {
    setLoading(true);
    try {
      if (dialedInAsyncTaskId != null) {
        throw new Error("A call is still active in the Tasks tab.");
      }
      if (dialedInPhoneNumber != null) {
        throw new Error("A manual dial in call is still active.");
      }

      if (!isPhoneCall) {
        handleFacilityActivity();
      }

      // If there is a webex or phone call in progress,
      // put it on hold before dialing new one
      if (dialedInTaskAssignmentId && !webexActiveCallId) {
        await dispatch(
          updateTaskAssignmentLocalState({ holdCallOnDisconnect: true })
        );
        await disconnectPhoneCall();
        await queueVmosCall();
        return;
      } else if (webexActiveCallId) {
        await disconnectFromCall(webexActiveCallId);
      }
      await connectToVmosCall(task, store, snacks);
    } catch (e) {
      snacks.showErrorSnack(`An error occurred while connecting to call: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePutOnHold = async () => {
    // Phone: disconnects browser client while customer stays on hold
    // WebEx: disconnects from call completely
    setLoading(true);
    try {
      if (isPhoneCall) {
        await dispatch(
          updateTaskAssignmentLocalState({ holdCallOnDisconnect: true })
        );
        await disconnectPhoneCall();
      } else {
        handleFacilityActivity();
        await endWebexCall();
        dispatch(updateTaskAssignmentLocalState({}));
      }
    } catch (e) {
      snacks.showErrorSnack("An error occurred while putting on hold.");
      dispatch(updateTaskAssignmentLocalState({}));
    } finally {
      setLoading(false);
    }
  };

  const handleHangUp = async () => {
    // Phone: Ends call. Also disconnects browser client if active call
    // WebEx: Does nothing if inactive. Disconnects if active call
    setLoading(true);
    try {
      if (isPhoneCall && isDialedInCall) {
        await disconnectPhoneCall();
      } else if (isPhoneCall && !isDialedInCall && !callEnded) {
        await endPhoneCall(task, store, snacks);
      } else {
        handleFacilityActivity();
        if (isDialedInCall) {
          await endWebexCall();
          dispatch(updateTaskAssignmentLocalState({}));
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenReassignModal = () => {
    setReassignModalOpen(true);
  };

  const handleReassignCall = async (userId: string) => {
    try {
      if (isDialedInCall) {
        handlePutOnHold();
      }
      if (!userId) {
        throw new Error("No user selected.");
      }
      const userIdNumber: number = parseInt(userId);
      if (isNaN(userIdNumber)) {
        throw new Error("Invalid ID selected.");
      }
      setReassignModalLoading(true);
      await dispatch(reassignTaskAssignment(task.id, userIdNumber));
      snacks.showSuccessSnack("Task transferred.");
    } catch (e: unknown) {
      let message;
      if (typeof e === "string") {
        message = e;
      } else if (e instanceof Error) {
        message = e.message;
      }
      snacks.showErrorSnack(message);
    } finally {
      setReassignModalLoading(false);
    }
  };

  /**
   * For CallScript
   */
  const callScriptsPrefill = getCallScriptsPrefill(task);

  const handleCompleteResolution = async (
    isUsingOrgResolution: boolean,
    resolution: string
  ) => {
    try {
      setLoading(true);
      if (!resolution) {
        throw new Error("Resolution not filled.");
      }
      await handleHangUp();

      let realtimeTaskPartial;
      if (!isUsingOrgResolution) {
        if (
          !Object.values<string>(CustomerInteractionResolution).includes(
            resolution
          )
        ) {
          throw new Error("Invalid resolution.");
        }

        realtimeTaskPartial = {
          resolution: resolution as CustomerInteractionResolution,
        };
      } else {
        realtimeTaskPartial = { orgResolution: resolution };
      }

      await updateRealtimeTask(task.realtimeTask.id, realtimeTaskPartial);
      await updateTaskAssignment(task.id, {
        status: RealtimeTaskStatus.Completed,
      });
      dispatch(removeByTaskAssignmentId(task.id));
      dispatch(removeByTaskAssignmentIdFromAllTasks(task.id));
      snacks.showSuccessSnack("Task completed.");
    } catch (e) {
      snacks.showErrorSnack(
        `An error occurred while completing resolution: ${e}`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelectLead = async (tenant: Tenant) => {
    const res = await updateRealtimeTask(task.realtimeTask.id, {
      tenantId: tenant.id,
    });
    const updatedAssignment = { ...task };
    updatedAssignment.realtimeTask = res.data;
    dispatch(upsertTaskAssignment(updatedAssignment));
  };

  const handleUpdateFacility = async (facilityId: number) => {
    const res = await updateRealtimeTask(task.realtimeTask.id, { facilityId });
    const updatedAssignment = { ...task, realtimeTask: res.data };
    dispatch(upsertTaskAssignment(updatedAssignment));
  };

  /**
   * InfoCard props
   */
  const taskTypeInfo = getTaskTypeInfo(task);

  const infoArray: InfoNodeType[] = [];

  const facilityDisplayNameLabel = displayName(facility);
  if (facility) {
    // Show clickable facility name
    infoArray.push({
      label: facilityDisplayNameLabel,
      onClick: () => {
        setFacilityInfoModalOpen(true);
      },
    });
  } else {
    const taskAssignmentPhone = getTaskAssignmentPhone(task);
    const taskAssignmentDisplayPhone = formatE164ToDisplay(taskAssignmentPhone);
    if (taskAssignmentPhone && taskAssignmentDisplayPhone) {
      // Show unknown facility with phone number called
      infoArray.push({
        label: `${facilityDisplayNameLabel} - ${taskAssignmentDisplayPhone}`,
      });
    } else {
      // Show unknown facility without phone number
      infoArray.push({
        label: facilityDisplayNameLabel,
      });
    }
  }

  infoArray.push(
    getFacilityStatusInfo(
      task,
      isCounterCallWithUnhandledActivity,
      isDialedInCall,
      timeAcceptedLocal
    )
  );

  infoArray.push(getTenantInfo(task));

  if (task.reassignedBy) {
    const reassignInfo = {
      label: `Transferred by ${getFirstName(task.reassignedBy)}`,
      badge: true,
    };
    infoArray.push(reassignInfo);
  }
  const infoCardProps = {
    title: taskTypeInfo.title,
    info: infoArray,
    Icon: taskTypeInfo.Icon,
  };

  const getCallConversations = async (): Promise<Conversation[]> => {
    // Conversation state already set.
    if (conversations) {
      return conversations;
    }

    // Attempt to get conversation from tenant
    return getConversations(tenant);
  };

  const handleOpenConversation = async () => {
    try {
      setIsJoiningConversation(true);
      const callConversations = await getCallConversations();
      setConversations(callConversations);
      await Promise.all(
        callConversations.map((callConvo) =>
          addUserToConversation({
            conversationId: callConvo.id,
            userId: currentAgent.id,
          })
        )
      );
      setConversationModalOpen(true);
    } catch (e) {
      handleOpenConversationException(e, snacks.showErrorSnack);
    } finally {
      setIsJoiningConversation(false);
    }
  };

  const handleCloseConversation = () => {
    setConversationModalOpen(false);
  };

  return (
    <CallCardContext.Provider
      value={{
        taskId: task.id,
        taskTimeReceived,
        loading,
        reassignModalOpen,
        reassignModalLoading,
        facilityInfoModalOpen,
        isIncomingCall,
        isCounterCallWithUnhandledActivity,
        isDialedInCall,
        isTwilioCall,
        isPhoneCall,
        callActionDisabled,
        dialInDisabled,
        hangUpDisabled,
        virtualManagersOnline,
        unhandledFacilityActivity,
        handleFacilityActivity,
        setReassignModalOpen,
        setFacilityInfoModalOpen,
        handleAcceptCall,
        handleDismissCall,
        handlePassCall,
        handlePutOnHold,
        handleDialIn,
        handleHangUp,
        handleOpenReassignModal,
        handleReassignCall,
        handleCompleteResolution,
        handleSelectLead,
        handleUpdateFacility,
        tenant,
        callScriptsPrefill,
        facility,
        source,
        infoCardProps,
        conversations,
        conversationModalOpen,
        isJoiningConversation,
        handleOpenConversation,
        handleCloseConversation,
      }}
    >
      {children}
    </CallCardContext.Provider>
  );
};

export const useCallCard = () => useContext(CallCardContext);

export const MockCallCardProvider = createMockContextProvider(
  CallCardContext,
  initialValue
);
