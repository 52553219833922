import React, { forwardRef } from "react";
import cn from "classnames";
import { LoadingIndicator } from "componentsV2/LoadingIndicator";

export enum ButtonVariant {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  WHITE = "WHITE",
}

export enum ButtonColor {
  NAVY = "NAVY",
  RED = "RED",
  GREEN = "GREEN",
}

export enum ButtonSize {
  REGULAR = "REGULAR",
  SMALL = "SMALL",
}

interface SharedProps {
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  Icon?: React.ElementType;
}

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    SharedProps {
  loading?: boolean;
}

export interface ButtonLinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "color">,
    SharedProps {}

// Color properties grouped by variant
const buttonColorClasses: Record<
  ButtonVariant,
  Record<ButtonColor, string> & { variantClasses: string }
> = {
  [ButtonVariant.PRIMARY]: {
    variantClasses:
      "text-white shadow-sm border focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    [ButtonColor.NAVY]:
      "bg-navy-900 border-navy-900 hover:bg-navy-700 focus-visible:outline-navy-900",
    [ButtonColor.RED]:
      "bg-red-700 border-red-700 hover:bg-red-600 focus-visible:outline-red-700",
    [ButtonColor.GREEN]:
      "bg-green-600 border-green-600 hover:bg-green-700 focus-visible:outline-green-600",
  },
  [ButtonVariant.SECONDARY]: {
    variantClasses:
      "bg-white shadow-sm border hover:bg-gray-50 focus-visible:border-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    [ButtonColor.NAVY]:
      "text-navy-700 border-navy-700 focus-visible:outline-navy-900",
    [ButtonColor.RED]:
      "text-red-700 border-red-700 focus-visible:outline-red-700",
    [ButtonColor.GREEN]:
      "text-green-600 border-green-600 focus-visible:outline-green-600",
  },
  [ButtonVariant.WHITE]: {
    variantClasses:
      "border border-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    [ButtonColor.NAVY]: "text-navy-700 focus-visible:outline-navy-900",
    [ButtonColor.RED]: "text-red-700 focus-visible:outline-red-700",
    [ButtonColor.GREEN]: "text-green-600 focus-visible:outline-green-600",
  },
};

// border-radius, padding, font-size, font-weight
const buttonSizeClasses: Record<ButtonSize, string> = {
  [ButtonSize.REGULAR]: "rounded-md py-2 px-3 text-sm leading-5 font-medium",
  [ButtonSize.SMALL]: "rounded-[4px] py-1 px-2 text-xs leading-4 font-medium",
};

const getButtonClasses = (
  size: ButtonSize,
  variant: ButtonVariant,
  color: ButtonColor,
  loading?: boolean
) => {
  const sizeClasses = buttonSizeClasses[size];
  const { variantClasses, [color]: colorClasses } = buttonColorClasses[variant];
  const displayClasses = "inline-flex items-center gap-x-2 relative";
  // Ignore disabled styles when disabled due to loading
  const disabledClasses = loading
    ? ""
    : "disabled:opacity-60 disabled:cursor-not-allowed";

  return cn(
    sizeClasses,
    variantClasses,
    colorClasses,
    displayClasses,
    disabledClasses
  );
};

const renderButtonContent = (
  size: ButtonSize,
  children?: React.ReactNode,
  Icon?: React.ElementType,
  loading?: boolean
) => {
  const smallIcon = size === ButtonSize.SMALL;
  const hasLabel = !!children;
  const hasIcon = !!Icon;
  const offsetIcon = hasIcon && hasLabel;
  const loadingReplacesLabel = loading && !hasIcon;

  return (
    <>
      {hasIcon && (
        <Icon
          className={cn(smallIcon ? "h-4 w-4" : "h-5 w-5", {
            "-ml-0.5": offsetIcon,
            hidden: loading,
          })}
          aria-hidden="true"
        />
      )}
      {loading && (
        <LoadingIndicator
          className={cn({
            "!h-4 !w-4": smallIcon,
            "-ml-0.5": offsetIcon,
            "absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2":
              loadingReplacesLabel,
          })}
        />
      )}
      {hasLabel && (
        <span className={cn({ "opacity-0": loadingReplacesLabel })}>
          {children}
        </span>
      )}
    </>
  );
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant = ButtonVariant.PRIMARY,
      color = ButtonColor.NAVY,
      size = ButtonSize.REGULAR,
      Icon,
      loading,
      disabled,
      ...buttonProps
    },
    buttonRef
  ) => {
    return (
      <button
        ref={buttonRef}
        className={cn(
          getButtonClasses(size, variant, color, loading),
          className
        )}
        disabled={disabled || loading}
        {...buttonProps}
      >
        {renderButtonContent(size, children, Icon, loading)}
      </button>
    );
  }
);

Button.displayName = "Button";

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      className,
      variant = ButtonVariant.PRIMARY,
      color = ButtonColor.NAVY,
      size = ButtonSize.REGULAR,
      Icon,
      ...buttonProps
    },
    buttonLinkRef
  ) => {
    return (
      <a
        ref={buttonLinkRef}
        className={cn(getButtonClasses(size, variant, color), className)}
        {...buttonProps}
      >
        {renderButtonContent(size, children, Icon)}
      </a>
    );
  }
);

ButtonLink.displayName = "ButtonLink";
