import React from "react";

import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";
import { useSelector } from "hooks/redux";
import { facilitiesSelector } from "state/facilitiesSlice";
import { displayName } from "utils/facility";
import { FilterGroup } from "./FilterGroup";
import { FilterReset } from "./FilterReset";
import { FilterToggle } from "./FilterToggle";
import { Filter, FilterType } from "./types";

interface FiltersProps {
  filters: Filter[];
}

export const Filters: React.FC<FiltersProps> = ({ filters }) => {
  const facilities = useSelector(facilitiesSelector);
  const { asyncTaskFilters, setFacilityIdsFilter, setSkillRequirementsFilter } =
    useAsyncTaskFilters();

  const renderFilter = (filter: Filter) => {
    switch (filter.type) {
      case FilterType.Group: {
        const { label, resourcetype, skillRequirements } = filter;
        return (
          <FilterGroup
            key={resourcetype}
            label={label}
            items={skillRequirements}
            activeItems={asyncTaskFilters.skillRequirements ?? []}
            handleUpdateItems={(items) => {
              setSkillRequirementsFilter(resourcetype, items);
            }}
            buttonIsActive={resourcetype === asyncTaskFilters.resourcetype}
          />
        );
      }
      case FilterType.Toggle: {
        const { label, resourcetype } = filter;
        return (
          <FilterToggle
            key={resourcetype}
            label={label}
            resourcetype={resourcetype}
          />
        );
      }
      default:
        return null;
    }
  };

  const facilityItems = facilities.map((facility) => ({
    label: displayName(facility),
    value: facility.id.toString(),
  }));
  const facilityActiveItems = asyncTaskFilters?.facilityIds ?? [];

  return (
    <div className="flex items-center gap-3.5">
      <FilterReset />
      <div className="w-0.5 h-full rounded-sm bg-gray-300" />
      {filters.map(renderFilter)}
      <div className="ml-auto flex items-center gap-2">
        <div className="text-xs leading-4 font-medium text-navy-900">For:</div>
        <FilterGroup
          label={
            facilityActiveItems.length > 0 ? "Facilities" : "All facilities"
          }
          items={facilityItems}
          activeItems={facilityActiveItems}
          handleUpdateItems={(items) => {
            setFacilityIdsFilter(items);
          }}
          handleReset={
            facilityActiveItems.length > 0
              ? () => setFacilityIdsFilter([])
              : undefined
          }
          buttonIsActive
          panelClassName="right-0"
          showFilterInput
        />
      </div>
    </div>
  );
};
