import React, {
  createContext,
  useContext,
  useEffect,
  useId,
  useState,
} from "react";
import { compare } from "fast-json-patch";
import { FormikValues, useFormik } from "formik";

import {
  FacilityMetadataCreateType,
  FacilityMetadata,
  FacilityMetadataType,
  FacilityMetadataDataType,
  SimpleFacility,
} from "api/types";
import { ModalProps } from "componentsV2/Modal";
import { useDispatch, useSelector } from "hooks/redux";
import {
  facilitiesMetadataSelector,
  facilityMetadataSelector,
  upsertFacilityMetadata,
} from "state/facilitiesMetadataSlice";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
import { FacilityMetadataFormField, InputProps } from "../types";
import { displayName } from "utils/facility";
import { useFetchFacilityMetadataQuery } from "state/facilityApi";
import { setSavingCompleted } from "state/facilitiesMetadataSlice";
interface FacilityInfoContextValueBase {
  facilityTitle: string;
  loading: boolean;
  saving: boolean;
  formId: string;
  metadata: FacilityMetadata[];
  metadataGroups: Record<string, FacilityMetadata[]>;
  formFieldsGroups: Record<string, FacilityMetadataFormField[]>;
  isEditing: boolean;
  formSubmitDisabled: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  addFormFieldAlert: () => void;
  addFormFieldNote: () => void;
  removeFormField: (fieldName: string) => void;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  getInputProps: (fieldName: string) => InputProps;
  modal: false;
}

interface FacilityInfoContextValueModal
  extends Omit<FacilityInfoContextValueBase, "modal"> {
  modal: true;
  modalActive: ModalProps["active"];
  handleCloseModal: ModalProps["handleClose"];
}

type FacilityInfoContextValue =
  | FacilityInfoContextValueBase
  | FacilityInfoContextValueModal;

const initialValue: FacilityInfoContextValue = {
  facilityTitle: "Facility",
  loading: false,
  saving: false,
  formId: "facility-info-form",
  metadata: [],
  metadataGroups: { all: [], alerts: [], info: [], configs: [] },
  formFieldsGroups: { alerts: [], info: [] },
  isEditing: false,
  formSubmitDisabled: false,
  setIsEditing: emptyFn,
  addFormFieldAlert: emptyFn,
  addFormFieldNote: emptyFn,
  removeFormField: emptyFn,
  handleSubmit: asyncEmptyFn,
  getInputProps: () => ({
    value: "",
    onChange: emptyFn,
    onBlur: emptyFn,
  }),
  modal: false,
};

const FacilityInfoContext =
  createContext<FacilityInfoContextValue>(initialValue);

interface FacilityInfoProviderPropsBase {
  children: React.ReactNode;
  facility: SimpleFacility;
  modal: false;
  modalActive?: ModalProps["active"];
  handleCloseModal?: ModalProps["handleClose"];
}

interface FacilityInfoProviderPropsModal
  extends Omit<FacilityInfoProviderPropsBase, "modal"> {
  modal: true;
  modalActive: ModalProps["active"];
  handleCloseModal: ModalProps["handleClose"];
}

type FacilityInfoProviderProps =
  | FacilityInfoProviderPropsBase
  | FacilityInfoProviderPropsModal;

export const FacilityInfoProvider: React.FC<FacilityInfoProviderProps> = ({
  children,
  facility,
  modal,
  modalActive,
  handleCloseModal,
}) => {
  const formId = useId();
  const [isEditing, setIsEditing] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<
    Record<string, string>
  >({});
  const [formFields, setFormFields] = useState<FacilityMetadataFormField[]>([]);

  const dispatch = useDispatch();
  const { refetch, isUninitialized } = useFetchFacilityMetadataQuery(
    facility.id,
    {
      skip: modal && !modalActive, // Skip when rendered as inactive modal
    }
  );
  // Fallback to empty array since metadata can be undefined
  const metadata = useSelector(facilityMetadataSelector(facility.id)) ?? [];
  const { loading, loadingCompleted, saving, savingCompleted, metadataIdMap } =
    useSelector(facilitiesMetadataSelector);

  // Fetch metadata when changes are saved
  useEffect(() => {
    if (savingCompleted) {
      setIsEditing(false);
      if (!isUninitialized) {
        refetch();
      }
      dispatch(setSavingCompleted(false));
    }
  }, [savingCompleted]);

  // Initializes form fields
  useEffect(() => {
    if (!loadingCompleted) return;

    const fields = metadata.map<FacilityMetadataFormField>((m) => {
      return {
        name: m.name,
        label: m.description,
        type: "text",
        metadataId: m.id,
        metadataType: m.type,
        newField: m.id === -1 ? true : false,
        deletable: !m.default,
        dataType: m.dataType,
        isEditable: m.isEditable,
      };
    });

    setInitialFormValues(
      metadata.reduce((v: Record<string, string>, m) => {
        if (m.id !== -1) {
          v[m.name] = m.value;
        }
        return v;
      }, {})
    );
    setFormFields(fields);
  }, [loadingCompleted, facility, metadataIdMap[facility.id]]);
  // Groups facility meta into alerts and non-alerts
  const metadataGroups = metadata.reduce(
    (acc, curr) => {
      if (curr.value) {
        if (curr.type === FacilityMetadataType.Alerts) {
          acc.alerts.push(curr);
        } else if (curr.type === FacilityMetadataType.Configs) {
          acc.configs.push(curr);
        } else {
          acc.info.push(curr);
        }
      }
      return acc;
    },
    { all: metadata, alerts: [], info: [], configs: [] } as Record<
      string,
      FacilityMetadata[]
    >
  );

  // Group form fields
  // TODO: do concurrently with metadata groups?
  const formFieldsGroups = formFields.reduce(
    (acc, curr) => {
      if (curr.metadataType === FacilityMetadataType.Alerts) {
        acc.alerts.push(curr);
      } else if (curr.isEditable !== false) {
        acc.info.push(curr);
      }
      return acc;
    },
    { alerts: [], info: [] } as Record<string, FacilityMetadataFormField[]>
  );

  const idMapping = metadataIdMap[facility.id];

  /* Form field actions */
  const addFormFieldAlert = () => {
    const nextIndex = formFieldsGroups.alerts.length + 1;
    setFormFields((curr) => [
      ...curr,
      {
        name: `facility_alert_${nextIndex}`,
        label: `Facility Alert ${nextIndex}`,
        type: "text",
        metadataType: FacilityMetadataType.Alerts,
        newField: true,
        deletable: true,
        dataType: FacilityMetadataDataType.String,
        isEditable: true,
      },
    ]);
  };

  const addFormFieldNote = () => {
    let lastNoteCount = 1;
    formFields.forEach((f) => {
      const match = f.name.match(/facility_note_([0-9]+)/);
      if (match && match[1] && lastNoteCount < Number(match[1]) + 1) {
        lastNoteCount = Number(match[1]) + 1;
      }
    });

    setFormFields((curr) => [
      ...curr,
      {
        name: `facility_note_${lastNoteCount}`,
        label: `Facility Note ${lastNoteCount}`,
        type: "text",
        metadataType: FacilityMetadataType.Notes,
        newField: true,
        deletable: true,
        dataType: FacilityMetadataDataType.String,
        isEditable: true,
      },
    ]);
  };

  const removeFormField = (fieldName: string) => {
    setFormFields(formFields.filter((f) => f.name !== fieldName));
    formik.setFieldValue(fieldName, undefined);
  };

  /* Metadata form */
  const handleFormikSubmit = async (values: FormikValues) => {
    const formDiff = compare(initialFormValues, values);

    const updateMetadata: { metadataId: number; value: string }[] = [];
    const createMetadata: {
      facilityId: number;
      data: FacilityMetadataCreateType;
    }[] = [];

    formDiff.forEach((diff) => {
      const name = diff.path.replace(/^\//, "");
      if (diff.op === "replace") {
        updateMetadata.push({
          metadataId: idMapping[name],
          value: diff.value,
        });
      } else if (diff.op === "add") {
        const field = formFields.find((a) => a.name === name);
        if (field) {
          createMetadata.push({
            facilityId: facility.id,
            data: {
              type: field.metadataType,
              name: field.name,
              description: field.label,
              value: diff.value,
            },
          });
        }
      } else if (diff.op === "remove") {
        /* temporary set remove field to empty until delete endpoint is available */
        updateMetadata.push({
          metadataId: idMapping[name],
          value: "",
        });
      }
    });

    dispatch(upsertFacilityMetadata({ updateMetadata, createMetadata }));
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    onSubmit: handleFormikSubmit,
  });

  const formSubmitDisabled = !formik.dirty || formik.isSubmitting;

  const getInputProps = (fieldName: string) => ({
    value: formik.values[fieldName] ?? "",
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  });

  const handleSubmit = formik.handleSubmit;

  // Type guard modalActive and handleCloseModal
  const modalValues = modal
    ? { modal, modalActive, handleCloseModal }
    : { modal };

  return (
    <FacilityInfoContext.Provider
      value={{
        facilityTitle: displayName(facility),
        loading,
        saving,
        formId,
        metadata,
        metadataGroups,
        formFieldsGroups,
        isEditing,
        formSubmitDisabled,
        setIsEditing,
        addFormFieldAlert,
        addFormFieldNote,
        removeFormField,
        getInputProps,
        handleSubmit,
        ...modalValues,
      }}
    >
      {children}
    </FacilityInfoContext.Provider>
  );
};

export const useFacilityInfo = () => useContext(FacilityInfoContext);

export const MockFacilityInfoProvider = createMockContextProvider(
  FacilityInfoContext,
  initialValue
);
