import {
  AsyncTask,
  AsyncTaskAssignmentType,
  AsyncTaskResourceType,
  AsyncTaskFilters,
  CallScriptsPrefill,
  Tenant,
  MessageTask,
  MissedCallFollowUpTask,
  User,
  MessageTaskMessage,
} from "api/types";
import { formatE164ToDisplay } from "utils/phoneNumber";
import { getTenantPrimaryPhoneNumber } from "utils/tenant";

export const getAsyncTaskAssignmentStatusText = (
  status: AsyncTaskAssignmentType
) => {
  switch (status) {
    case AsyncTaskAssignmentType.InProgress:
      return "In Progress";
    case AsyncTaskAssignmentType.Revisit:
      return "Revisit";
    case AsyncTaskAssignmentType.Completed:
      return "Completed";
    default: {
      const formattedStatus = (status as string)
        ?.split("_")
        .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
        .join(" ");
      return formattedStatus ?? "";
    }
  }
};

const asyncTaskResolutionKeyRecord: Record<AsyncTaskResourceType, string> = {
  [AsyncTaskResourceType.LeadFollowUpTask]: "customerInteractionResult",
  [AsyncTaskResourceType.MessageTask]: "customerInteractionResult",
  [AsyncTaskResourceType.MissedCallFollowUpTask]: "customerInteractionResult",
  [AsyncTaskResourceType.AsyncTask]: "resolution",
};

/**
 * Returns preferred field key for task resolutions
 * @param task AsyncTask
 * @returns "resolution" if V1 async task, "customerInteractionResult" otherwise
 */
export const getAsyncTaskResolutionKey = (task: AsyncTask): string =>
  asyncTaskResolutionKeyRecord[task.resourcetype] ??
  asyncTaskResolutionKeyRecord[AsyncTaskResourceType.AsyncTask];

export const getAsyncTaskCustomer = (task: AsyncTask): Tenant | undefined => {
  switch (task.resourcetype) {
    case AsyncTaskResourceType.LeadFollowUpTask:
      return task.leadTenant;
    case AsyncTaskResourceType.MessageTask:
      return task.customer;
    case AsyncTaskResourceType.MissedCallFollowUpTask:
      return task.caller;
    case AsyncTaskResourceType.AsyncTask:
    default: {
      return task.tenant;
    }
  }
};

export const getLatestMissedCallPhoneNumber = (
  task: MissedCallFollowUpTask
): string | undefined => {
  // Sort missed calls by latest/descending timeCreated
  const sortedMissedCalls = [...task.missedCalls].sort((a, b) =>
    a.timeCreated < b.timeCreated ? 1 : a.timeCreated > b.timeCreated ? -1 : 0
  );

  // Find first/latest call with defined fromPhoneNumber
  const latestCallWithNumber = sortedMissedCalls.find(
    ({ fromPhoneNumber }) => !!fromPhoneNumber
  );

  return latestCallWithNumber?.fromPhoneNumber;
};

export const getTaskPhoneNumber = (task: AsyncTask): string | undefined => {
  // If MissedCallFollowUpTask, find latest missedCall with a fromPhoneNumber.
  // If none found, try to get from tenant (like with other task types).
  if (task.resourcetype === AsyncTaskResourceType.MissedCallFollowUpTask) {
    const latestNumber = getLatestMissedCallPhoneNumber(task);
    if (latestNumber) {
      return latestNumber;
    }
  } else if (task.resourcetype === AsyncTaskResourceType.MessageTask) {
    return task.conversation.customerPhoneNumber;
  }

  // For other task types (and MissedCallFollowUp if no missedCall number was found),
  // get primary phone number from customer (if found).
  // Otherwise fallback to number from phoneCall (deprecated async task v1 field)
  const customer = getAsyncTaskCustomer(task);

  return (
    (customer && getTenantPrimaryPhoneNumber(customer)) ||
    task.phoneCall?.fromPhoneNumber
  );
};

export const getCallScriptsPrefill = (task: AsyncTask): CallScriptsPrefill => {
  const prefillPhoneNumber = getTaskPhoneNumber(task);

  return {
    phoneNumber: prefillPhoneNumber
      ? formatE164ToDisplay(prefillPhoneNumber)
      : "",
  };
};

export const checkIfAsyncTaskMatchesFilters = (
  task: AsyncTask,
  filters: Partial<AsyncTaskFilters> = {}
): boolean => {
  const resourcetype = filters?.resourcetype;
  const skillRequirements = filters?.skillRequirements ?? [];
  const facilityIds = filters?.facilityIds ?? [];

  const taskFacilityIdString = task.facilityId?.toString() ?? "";
  const facilityIdMatches =
    facilityIds.length === 0 || facilityIds.includes(taskFacilityIdString);

  // Early return with only facilityId check if no other filters are set
  if (!resourcetype) {
    return facilityIdMatches;
  }

  const resourcetypeMatches = task.resourcetype === resourcetype;
  const skillRequirementsMatches =
    skillRequirements.length === 0 ||
    (task.skillRequirements.length === 0
      ? skillRequirements.includes("null")
      : task.skillRequirements.reduce(
          (acc, curr) => acc || skillRequirements.includes(curr.skill.name),
          false
        ));

  // Returns true if:
  // - resourcetype matches
  // - any skillRequirements are included in filters
  // - facilityId is defined and included in filters
  // Returns false otherwise.

  return resourcetypeMatches && skillRequirementsMatches && facilityIdMatches;
};

/**
 * Checks if the task is assigned to a user
 * @param task AsyncTask to check
 * @param user current user
 * @returns whether or not the task is assigned to the user
 */
export const checkIfAsyncTaskIsAssignedToUser = (
  task: AsyncTask,
  user: User
) => {
  const ataLength = task.asyncTaskAssignments.length;
  if (ataLength === 0) {
    return false;
  }

  const lastAta = task.asyncTaskAssignments[ataLength - 1];
  const assigneeIdMatches = lastAta.assigneeId === user.id;
  const validAtaStatus = lastAta.status === AsyncTaskAssignmentType.InProgress;

  return assigneeIdMatches && validAtaStatus;
};

/**
 * Returns whether or not a MessageTaskMessage is new (not seen & from customer)
 * NOTE: Assumes that messages with source: "SDK" are sent by VMs
 * @param mtm MessageTaskMessage
 * @returns boolean
 */
const isNewMessage = (mtm: MessageTaskMessage) =>
  mtm.source !== "SDK" && !mtm.seen;

export const getNewMessagesCount = (task: MessageTask): number =>
  task.messageTaskMessages.filter(isNewMessage).length;

export const getLatestNewMessage = (
  task: MessageTask
): MessageTaskMessage | undefined => {
  const newMessages = task.messageTaskMessages.filter(isNewMessage);
  return newMessages.length ? newMessages[newMessages.length - 1] : undefined;
};

export const getAsyncTaskPhone = (task: AsyncTask): string =>
  task.phoneCall?.facilityPhoneNumber || "";
