import React, { useMemo, useState } from "react";
import { Select } from "componentsV2/Inputs";
import { useSelector } from "hooks/redux";
import { useSnackbar } from "modulesV2/Snackbar";
import { facilitiesSelector } from "state/facilitiesSlice";
import { displayName, isFacilityLiveOrPreleasing } from "utils/facility";
import { useCallScripts } from "../../contexts/CallScriptsContext";

export const FacilitySelect: React.FC = () => {
  const [updating, setUpdating] = useState(false);
  const { facilityId, handleUpdateFacility } = useCallScripts();
  const facilities = useSelector(facilitiesSelector);
  const { showErrorSnack } = useSnackbar();

  const selectOptions = useMemo<{ value: string; label: string }[]>(
    () =>
      facilities.filter(isFacilityLiveOrPreleasing).map((facility) => ({
        value: facility.id.toString(),
        label: displayName(facility),
      })),
    [facilities]
  );

  const handleChangeSelect: React.ChangeEventHandler<
    HTMLSelectElement
  > = async (e) => {
    try {
      const currentValue = e.currentTarget.value;
      const parsedValue = parseInt(currentValue);
      if (typeof parsedValue !== "number" || isNaN(parsedValue)) {
        throw new Error(`Invalid ID ${currentValue}`);
      }

      setUpdating(true);
      await handleUpdateFacility(parsedValue);
    } catch (e) {
      console.error(e);
      const errorMessage =
        e instanceof Error
          ? `Error: ${e.message}`
          : "Unable to update facility";
      showErrorSnack(errorMessage);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="border-b border-gray-200 pb-8 mb-4">
      <div className="max-w-xs">
        <Select
          value={facilityId?.toString() || ""}
          options={selectOptions}
          onChange={handleChangeSelect}
          label="Facility"
          name="facility"
          placeholder="Select facility"
          disabled={updating}
        />
      </div>
    </div>
  );
};
