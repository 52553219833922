import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MarketingSource } from "api/types";
import { Button } from "componentsV2/Button";
import { Input, Fieldset, Select } from "componentsV2/Inputs";
import { useSnackbar } from "modulesV2/Snackbar";
import { getErrorChecker } from "utils/forms/errors";
import { usaPhoneValidateRegex } from "utils/forms/validator";
import { LeadSelectionModal } from "./LeadSelectionModal";
import { FormResolution } from "./FormResolution";
import { TenantInformation } from "./TenantInformation";
import { useCallScripts } from "../contexts/CallScriptsContext";
import { CreateNewLeadValues, Script } from "../types";

export const testIds = {
  form: "new-lead-form",
  submit: "new-lead-submit",
  tenant: "new-lead-tenant",
};

export const FormNewLead: React.FC = () => {
  const {
    tenant,
    facilityId,
    prefill,
    isSubmittingCreateNewLead,
    hasCreatedNewLead,
    redirectScript,
    handleCreateNewLead,
  } = useCallScripts();

  const { showErrorSnack } = useSnackbar();

  const noFacilitySelected = typeof facilityId !== "number";

  /**
   * Redirects to Existing Lead or Tenant tab
   * when existing customer is selected.
   */
  const handleExistingSelected = () => {
    redirectScript();
  };

  const handleSubmit = (values: CreateNewLeadValues) => {
    if (noFacilitySelected) {
      showErrorSnack("Facility must be selected");
      return;
    }

    if (isSubmittingCreateNewLead) {
      return;
    }

    /**
     * Required value check handled by HTML5 form validation
     * in normal circumstances. Manual check as a workaround
     * for an open bug with jsdom where form submit handlers
     * still fire despite invalid form state.
     * https://github.com/jsdom/jsdom/issues/2898
     */
    const shouldSubmit = Object.keys(formik.errors).length === 0;
    if (shouldSubmit) {
      handleCreateNewLead(values);
    }
  };

  const formik = useFormik<CreateNewLeadValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      ...prefill,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phoneNumber: Yup.string()
        .required("Phone number is required")
        .matches(usaPhoneValidateRegex, "Phone number is not valid"),
      email: Yup.string().email("Email address is not valid"),
      marketingSource: Yup.string().required("Marketing source is required"),
    }),
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const inputHandlerProps = {
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  };

  const errorChecker = getErrorChecker(formik.errors, formik.touched);

  // Show tenant and resolution on successful submission
  if (tenant && hasCreatedNewLead) {
    return (
      <div data-testid={testIds.tenant}>
        <TenantInformation />
        <FormResolution className="mt-8" script={Script.NEW_LEAD} />
      </div>
    );
  }

  const marketingSelectOptions: { value: MarketingSource; label: string }[] = [
    {
      label: "Saw it driving by",
      value: MarketingSource.SawItDrivingBy,
    },
    {
      label: "Discovered on Google/Apple/Bing Maps",
      value: MarketingSource.OnlineMaps,
    },
    {
      label: "Saw a Google Ad",
      value: MarketingSource.GoogleAds,
    },
    {
      label: "Heard from a friend/family",
      value: MarketingSource.FriendsOrFamily,
    },
    {
      label: "Discovered on social media",
      value: MarketingSource.SocialMedia,
    },
    {
      label: "Others",
      value: MarketingSource.Others,
    },
  ];

  return (
    <>
      <LeadSelectionModal
        title="Tenant(s) already exists"
        description="Please select one of the following customers to match the phone number"
        handleOnSubmit={handleExistingSelected}
      />
      <form
        name="form-new-lead"
        onSubmit={formik.handleSubmit}
        className="grid gap-5"
        data-testid={testIds.form}
      >
        <Fieldset
          legend="What is your name?"
          fields={[
            {
              type: "text",
              name: "firstName",
              placeholder: "First name",
              value: formik.values.firstName,
              required: true,
              "aria-label": "First name",
              error: errorChecker("firstName"),
              disabled: noFacilitySelected,
              ...inputHandlerProps,
            },
            {
              type: "text",
              name: "lastName",
              placeholder: "Last name",
              value: formik.values.lastName,
              required: true,
              "aria-label": "Last name",
              error: errorChecker("lastName"),
              disabled: noFacilitySelected,
              ...inputHandlerProps,
            },
          ]}
        />
        <Input
          label="What is the best phone number to reach you at?"
          name="phoneNumber"
          type="tel"
          placeholder="(999) 999-9999"
          value={formik.values.phoneNumber}
          required
          aria-label="Phone number"
          error={errorChecker("phoneNumber")}
          disabled={noFacilitySelected}
          {...inputHandlerProps}
        />

        <Select
          options={marketingSelectOptions}
          name="marketingSource"
          label="How did you hear about us?"
          value={formik.values.marketingSource || ""}
          required
          error={errorChecker("marketingSource")}
          placeholder="Select marketing source"
          disabled={noFacilitySelected}
          {...inputHandlerProps}
        ></Select>

        <Input
          label="Do you have an email address? (Optional)"
          name="email"
          type="email"
          placeholder="example@website.com"
          value={formik.values.email}
          aria-label="Email address"
          error={errorChecker("email")}
          disabled={noFacilitySelected}
          {...inputHandlerProps}
        />
        <Button
          type="submit"
          className="w-max"
          loading={isSubmittingCreateNewLead}
          disabled={noFacilitySelected}
          data-testid={testIds.submit}
        >
          Create lead
        </Button>
      </form>
    </>
  );
};
