import { Button, ButtonColor } from "componentsV2/Button";
import React, { useEffect, useState } from "react";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { Badge, BadgeVariant } from "componentsV2/Badges";
import { useNavigate } from "react-router-dom";
import { createTaskAssignment } from "api/tasks";
import { useDispatch, useSelector } from "hooks/redux";
import { agentSelector } from "state/agentSlice";
import { fetchAllTasks, fetchCallQueue } from "services/tasks";
import { useSnackbar } from "modulesV2/Snackbar";
import {
  callQueueSelector,
  counterCallQueueSelector,
  currentTopCallSelector,
  currentTopCounterCallSelector,
  currentTopPhoneCallSelector,
  phoneCallQueueSelector,
} from "state/callQueueSlice";
import { CallQueueItem, RealtimeTaskType, Role } from "api/types";
import { CallQueueCallCount, CallQueueFilter } from "./types";
import { Path } from "routes/types";
import { acceptCall } from "services/tasks";
import { useStore } from "hooks/redux";
import { getElapsedTimeMinutes } from "utils/date";
import { formatE164ToDisplay } from "utils/phoneNumber";
import { FacilityInfo } from "modulesV2/FacilityInfo";
import { isDialedInSelector, upsertTaskAssignment } from "state/tasksSlice";
import {
  getCallCount,
  getCallQueueWaitingText,
  getInitialCallQueueFilter,
} from "./utils";
import { AxiosError } from "axios";
import { displayName } from "utils/facility";
import { Select } from "componentsV2/Inputs";
import createPersistedState from "use-persisted-state";
import { Howl } from "howler";
import { useClarity } from "hooks/clarity";
import { getTenantDisplayName } from "utils/tenant";

const useCallQueueFilterState =
  createPersistedState<CallQueueFilter>("call_queue_filter");

export const CallQueueBar: React.FC = () => {
  const agent = useSelector(agentSelector);
  const currentTopCall = useSelector(currentTopCallSelector);
  const currentTopPhoneCall = useSelector(currentTopPhoneCallSelector);
  const currentTopCounterCall = useSelector(currentTopCounterCallSelector);
  const currentCallQueue = useSelector(callQueueSelector);
  const currentPhoneCallQueue = useSelector(phoneCallQueueSelector);
  const currentCounterCallQueue = useSelector(counterCallQueueSelector);

  let topCallQueueItem: CallQueueItem | undefined = undefined;
  let callQueue: CallQueueItem[] | undefined = undefined;
  switch (agent.role) {
    case Role.PhoneOnly:
      topCallQueueItem = currentTopPhoneCall;
      callQueue = currentPhoneCallQueue;
      break;
    case Role.CounterOnly:
      topCallQueueItem = currentTopCounterCall;
      callQueue = currentCounterCallQueue;
      break;
    default:
      topCallQueueItem = currentTopCall;
      callQueue = currentCallQueue;
  }

  const [activeCallQueueItem, setActiveCallQueueItem] =
    useState(topCallQueueItem);
  const [processedCallQueueItems, setProcessedCallQueueItems] =
    useState<CallQueueItem[]>(callQueue);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snacks = useSnackbar();
  const callQueueSound = new Howl({
    src: ["/assets/callqueue.wav"],
  });
  const isDialedIn = useSelector(isDialedInSelector);
  const shouldMuteTaskAlert = agent.isSecondaryAlertMuted && isDialedIn;
  const [callQueueHasInitialized, setCallQueueHasInitialized] = useState(false);
  const store = useStore();
  const { claritySetAgentAcceptTask } = useClarity();
  const [facilityInfoOpen, setFacilityInfoOpen] = useState(false);

  const isPhoneCall = activeCallQueueItem?.type === RealtimeTaskType.PhoneCall;
  const Icon = isPhoneCall ? PhoneIcon : BuildingOfficeIcon;
  const skillRequirement = activeCallQueueItem?.skillRequirements?.reduce(
    (max, current) => {
      return current.weight > max.weight ? current : max;
    },
    activeCallQueueItem?.skillRequirements[0]
  )?.skill.name;
  const tenant = activeCallQueueItem?.tenant;
  const tenantName = getTenantDisplayName(tenant);
  const currentFacility = activeCallQueueItem?.facility;

  const [waitingTime, setWaitingTime] = useState(0);
  const selectOptions = [
    {
      label: `All Calls`,
      value: CallQueueFilter.AllCalls,
      disabled: !(agent.role === Role.Frontend || agent.role === Role.Backend),
    },
    {
      label: `Phone Calls Only`,
      value: CallQueueFilter.PhoneCallsOnly,
      disabled: agent.role === Role.CounterOnly,
    },
    {
      label: `Counter Calls Only`,
      value: CallQueueFilter.CounterCallsOnly,
      disabled: agent.role === Role.PhoneOnly,
    },
  ];

  const [filterValue, setFilterValue] = useCallQueueFilterState(
    getInitialCallQueueFilter(agent.role)
  );
  const [callCount, setCallCount] = useState<CallQueueCallCount>({
    counter: 0,
    phone: 0,
    all: 0,
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value as CallQueueFilter;
    setFilterValue(selectedValue);
  };

  const processCallQueueItems = (updatedCallQueueItems: CallQueueItem[]) => {
    if (callQueueHasInitialized) {
      const newCallQueueItems = updatedCallQueueItems.filter(
        (newItem) =>
          !processedCallQueueItems.some(
            (oldItem) => oldItem.url === newItem.url
          )
      );

      console.log(
        "Debug: items (existing, new)",
        processedCallQueueItems,
        newCallQueueItems
      );

      if (newCallQueueItems.length > 0) {
        newCallQueueItems.forEach((newCallQueueItem) => {
          const text = `New ${
            newCallQueueItem.type === RealtimeTaskType.PhoneCall
              ? "phone"
              : "counter"
          } call added to queue`;
          snacks.showInfoSnack(text);
        });
        if (!shouldMuteTaskAlert) {
          callQueueSound.play();
        }
      }
    }

    setProcessedCallQueueItems(updatedCallQueueItems);
  };

  const handleClaimCall = async () => {
    if (!activeCallQueueItem) {
      snacks.showErrorSnack("An error occurred while claiming the call.");
      return;
    }
    try {
      // Check if this call is still available to be claimed, for case that we missed a
      // pusher event
      const idString = activeCallQueueItem.url.split("/").pop() ?? "";
      const id = parseInt(idString);
      if (isNaN(id)) {
        throw new Error("Invalid RTT id");
      }
      const res = await createTaskAssignment(id, {
        status: "ACCEPTED",
        assignee_url: agent.url,
      });
      if (res.data) {
        navigate(Path.Calls);
        claritySetAgentAcceptTask(agent, res.data);
        dispatch(upsertTaskAssignment(res.data));
        await acceptCall(res.data, store, snacks);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 409) {
        snacks.showErrorSnack("This call is no longer available");
        try {
          dispatch(fetchCallQueue());
          dispatch(fetchAllTasks);
        } catch {
          snacks.showErrorSnack(
            "An error occurred while reloading call queue."
          );
        }
      } else {
        snacks.showErrorSnack("An error occurred while claiming the call.");
      }
    }
  };

  useEffect(
    function handleActiveCallQueueItem() {
      if (!activeCallQueueItem) return;
      // Set up an interval to increment the time by one minute every 60 seconds
      setWaitingTime(getElapsedTimeMinutes(activeCallQueueItem.timeCreated));
      const updateWaitingTimeInterval = setInterval(() => {
        setWaitingTime((prevTime) => prevTime + 1);
      }, 60000);

      console.log("Debug: activeCallQueueItem", activeCallQueueItem);

      return () => {
        clearInterval(updateWaitingTimeInterval);
      };
    },
    [activeCallQueueItem]
  );

  useEffect(
    function handleUpdatedCallQueue() {
      console.log("Debug: callQueue (live)", callQueue);
      if (callQueue) {
        processCallQueueItems(callQueue);
        setCallQueueHasInitialized(true);
      }
    },
    [callQueue]
  );

  useEffect(
    function handleUpdatedCallQueueFilter() {
      let callType: CallQueueItem | undefined;

      switch (filterValue) {
        case CallQueueFilter.PhoneCallsOnly:
          callType = currentTopPhoneCall;
          break;

        case CallQueueFilter.CounterCallsOnly:
          callType = currentTopCounterCall;
          break;

        case CallQueueFilter.AllCalls:
          callType = currentTopCall;
          break;

        default:
          return;
      }

      setActiveCallQueueItem(callType);
    },
    [filterValue, currentTopPhoneCall, currentTopCounterCall, currentTopCall]
  );

  useEffect(
    function updateCallCount() {
      console.log("Debug items (rendered):", processedCallQueueItems);
      const updatedCallCount = getCallCount(processedCallQueueItems);
      setCallCount(updatedCallCount);
    },
    [processedCallQueueItems]
  );

  const renderFacilityName = () => {
    // Regular facility (facility defined):
    // Render display name as a button to open info modal
    if (currentFacility) {
      return (
        <button
          type="button"
          onClick={() => setFacilityInfoOpen(true)}
          className="cursor-pointer underline"
        >
          {displayName(currentFacility)}
        </button>
      );
    }

    // OrgPhone (phoneCall.facilityPhoneNumber defined):
    // Render "Unknown - (###) ###-####" as text
    if (activeCallQueueItem?.phoneCall?.facilityPhoneNumber) {
      return `Unknown - ${formatE164ToDisplay(
        activeCallQueueItem.phoneCall.facilityPhoneNumber
      )}`;
    }

    // Fallback:
    // Render "Unknown" as text
    return "Unknown";
  };

  if (!topCallQueueItem) return null;

  return (
    <>
      <div className="flex text-sm justify-between bg-white border-gray-300 border-t items-center w-full p-4">
        <div className="flex gap-3 items-center">
          {activeCallQueueItem ? (
            <>
              <Icon
                className="flex-shrink-0 h-6 w-6 text-gray-900"
                aria-hidden="true"
              />
              <div className="font-semibold text-gray-500">Next call:</div>
              <div className="font-semibold">{renderFacilityName()}</div>
              {skillRequirement && (
                <Badge text={skillRequirement} variant={BadgeVariant.RED} />
              )}
              <div className="flex flex-row items-center gap-1 text-gray-500">
                {isPhoneCall && (
                  <>
                    <div>{tenantName}</div>
                    <span>•</span>

                    {activeCallQueueItem?.phoneCall?.fromPhoneNumber && (
                      <>
                        <div>
                          {formatE164ToDisplay(
                            activeCallQueueItem.phoneCall.fromPhoneNumber
                          )}
                        </div>
                        <span>•</span>
                      </>
                    )}
                  </>
                )}
                <div className="text-red-600 italic">
                  {`Waiting ${waitingTime} Minute${
                    waitingTime === 1 ? "" : "s"
                  }`}
                </div>
              </div>
            </>
          ) : null}
        </div>

        <div className="flex flex-row items-center gap-3 text-gray-500">
          <div>{getCallQueueWaitingText(filterValue, callCount)}</div>
          <Select
            aria-label="Change call queue filter"
            name="call-queue-filter-select"
            value={filterValue}
            onChange={handleChange}
            options={selectOptions}
            placeholder="Filter Queue by:"
            selectClassNames="!pl-2 !pr-7 "
          />
          <Button
            type="button"
            color={ButtonColor.GREEN}
            Icon={PhoneIcon}
            onClick={handleClaimCall}
            disabled={!activeCallQueueItem}
          >
            Claim Call
          </Button>
        </div>
      </div>
      {currentFacility && (
        <FacilityInfo
          facility={currentFacility}
          modal
          modalActive={facilityInfoOpen}
          handleCloseModal={() => setFacilityInfoOpen(false)}
        />
      )}
    </>
  );
};
