import React, { useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import { agentSelector } from "state/agentSlice";
import { handlePusherResourceEvent, pusher } from "services/Pusher";
import { Audio } from "componentsV2/Audio";
import { ExpandableText } from "componentsV2/ExpandableText";
import {
  CallTranscription,
  Feature,
  FeatureName,
  TranscriptStatus,
} from "api/types";
import { normalizeUrl, removeSignatureFromUrl } from "utils/task";
import { LoadingIndicator } from "componentsV2/LoadingIndicator";

export enum VoicemailVariant {
  REGULAR = "REGULAR",
  SMALL = "SMALL",
}

const voicemailClasses: Record<
  VoicemailVariant,
  {
    transcriptionClasses?: string;
    audioClasses?: string;
  }
> = {
  [VoicemailVariant.REGULAR]: {},
  [VoicemailVariant.SMALL]: {
    transcriptionClasses: "bg-gray-50 text-xs p-2",
    audioClasses: "flex justify-center",
  },
};

const getVoicemailClasses = (variant: VoicemailVariant) => {
  const { transcriptionClasses, audioClasses } = voicemailClasses[variant];
  return { transcriptionClasses, audioClasses };
};

interface VoicemailProps {
  initialTranscriptionText?: string;
  recordingUrl: string;
  variant?: VoicemailVariant;
  title?: string;
  transcriptStatus?: TranscriptStatus;
  onCallTranscriptionCreated?: (
    recordingUrl: string,
    callTranscription: CallTranscription
  ) => void;
}

export const Voicemail = forwardRef<HTMLDivElement, VoicemailProps>(
  (
    {
      initialTranscriptionText,
      recordingUrl,
      variant = VoicemailVariant.REGULAR,
      title = "",
      transcriptStatus,
      onCallTranscriptionCreated,
    },
    ref
  ) => {
    const [transcriptionText, setTranscriptionText] = useState(
      initialTranscriptionText ?? ""
    );
    const agent = useSelector(agentSelector);
    const isVoicemailTranscriptionEnabled =
      !!agent.organization &&
      agent.organization.features
        .map((feature: Feature) => feature.name)
        .includes(FeatureName.VoicemailTranscription);
    const [isTimeoutReached, setIsTimeoutReached] = useState(false);

    useEffect(() => {
      if (
        transcriptionText ||
        transcriptStatus === TranscriptStatus.COMPLETED ||
        !isVoicemailTranscriptionEnabled ||
        !recordingUrl ||
        !transcriptStatus ||
        transcriptStatus === TranscriptStatus.NOT_AVAILABLE ||
        isTimeoutReached
      )
        return;

      const timeoutId = setTimeout(() => {
        setIsTimeoutReached(true);
      }, 30000);

      const pushChannelPrefix = agent.organization?.pushChannelPrefix ?? "";
      if (!pushChannelPrefix) {
        return;
      }
      const transcriptCreatedChannelName =
        pushChannelPrefix + "CallTranscription_updated";

      const transcriptCreatedChannels = pusher.subscribe(
        transcriptCreatedChannelName
      );

      const handleTranscriptEvent = (data: CallTranscription) => {
        const unsignedUrl = removeSignatureFromUrl(recordingUrl);
        const normalizedDataUrl = normalizeUrl(data.recordingUrl);
        if (normalizedDataUrl === unsignedUrl) {
          setTranscriptionText(data.text);
          if (onCallTranscriptionCreated) {
            onCallTranscriptionCreated(unsignedUrl, data);
          }
        }
      };

      transcriptCreatedChannels.forEach((transcriptCreatedChannel) => {
        transcriptCreatedChannel.bind("model_updated", (event: unknown) =>
          handlePusherResourceEvent(event, handleTranscriptEvent)
        );
      });

      return () => {
        clearTimeout(timeoutId);
        transcriptCreatedChannels.forEach((transcriptCreatedChannel) => {
          transcriptCreatedChannel.unbind("model_updated");
        });
        pusher.unsubscribe(transcriptCreatedChannelName);
      };
    }, [transcriptionText, recordingUrl, transcriptStatus, isTimeoutReached]);

    const { transcriptionClasses, audioClasses } = getVoicemailClasses(variant);

    const renderTranscription = () => {
      if (
        !isVoicemailTranscriptionEnabled ||
        !transcriptStatus ||
        transcriptStatus === TranscriptStatus.NOT_AVAILABLE ||
        (transcriptStatus === TranscriptStatus.COMPLETED &&
          transcriptionText === "")
      ) {
        return null;
      }

      if (transcriptionText) {
        return (
          <div className={transcriptionClasses}>
            <ExpandableText
              text={transcriptionText}
              title={title}
              maxChars={250}
            />
          </div>
        );
      }

      if (!isTimeoutReached) {
        return (
          <div className="text-xs text-gray-700 leading-4 flex items-center">
            <LoadingIndicator className="!h-4 !w-4 text-gray-400  mr-2" />
            <span className="text-gray-500 font-semibold">
              Transcribing voicemail...
            </span>
          </div>
        );
      }
      return null;
    };

    return (
      <>
        {recordingUrl && (
          <div ref={ref} className="space-y-2">
            <Audio src={recordingUrl} className={audioClasses} />
            {renderTranscription()}
          </div>
        )}
      </>
    );
  }
);

Voicemail.displayName = "Voicemail";
