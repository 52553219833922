import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallQueueItem } from "api/types";
import { RootState } from "store";

const initialState: {
  callQueue: CallQueueItem[];
  phoneCallQueue: CallQueueItem[];
  counterCallQueue: CallQueueItem[];
  currentTopCall: CallQueueItem | undefined;
  currentTopPhoneCall: CallQueueItem | undefined;
  currentTopCounterCall: CallQueueItem | undefined;
  timeLastUpdated: string | undefined;
  sequenceId: number | undefined;
} = {
  callQueue: [],
  phoneCallQueue: [],
  counterCallQueue: [],
  currentTopCall: undefined,
  currentTopPhoneCall: undefined,
  currentTopCounterCall: undefined,
  timeLastUpdated: undefined,
  sequenceId: undefined,
};

export const callQueueSlice = createSlice({
  name: "callQueueSlice",
  initialState,
  reducers: {
    updateCallQueue: (
      state,
      action: PayloadAction<{ sequenceId: number; data: CallQueueItem[] }>
    ) => {
      if (
        typeof state.sequenceId !== "number" ||
        action.payload.sequenceId > state.sequenceId
      ) {
        state.callQueue = action.payload.data;
      }
    },
    updatePhoneCallQueue: (
      state,
      action: PayloadAction<{ sequenceId: number; data: CallQueueItem[] }>
    ) => {
      if (
        typeof state.sequenceId !== "number" ||
        action.payload.sequenceId > state.sequenceId
      ) {
        state.phoneCallQueue = action.payload.data;
      }
    },
    updateCounterCallQueue: (
      state,
      action: PayloadAction<{ sequenceId: number; data: CallQueueItem[] }>
    ) => {
      if (
        typeof state.sequenceId !== "number" ||
        action.payload.sequenceId > state.sequenceId
      ) {
        state.counterCallQueue = action.payload.data;
      }
    },
    updateCurrentTopCall: (
      state,
      action: PayloadAction<{
        sequenceId: number;
        data: CallQueueItem | undefined;
      }>
    ) => {
      if (
        typeof state.sequenceId !== "number" ||
        action.payload.sequenceId > state.sequenceId
      ) {
        state.currentTopCall = action.payload.data;
      }
    },
    updateCurrentTopPhoneCall: (
      state,
      action: PayloadAction<{
        sequenceId: number;
        data: CallQueueItem | undefined;
      }>
    ) => {
      if (
        typeof state.sequenceId !== "number" ||
        action.payload.sequenceId > state.sequenceId
      ) {
        state.currentTopPhoneCall = action.payload.data;
      }
    },
    updateCurrentTopCounterCall: (
      state,
      action: PayloadAction<{
        sequenceId: number;
        data: CallQueueItem | undefined;
      }>
    ) => {
      if (
        typeof state.sequenceId !== "number" ||
        action.payload.sequenceId > state.sequenceId
      ) {
        state.currentTopCounterCall = action.payload.data;
      }
    },
    updateTimeLastUpdated: (state, action: PayloadAction<string>) => {
      if (!state.timeLastUpdated || action.payload > state.timeLastUpdated) {
        state.timeLastUpdated = action.payload;
      }
    },
    updateSequenceId: (state, action: PayloadAction<number>) => {
      state.sequenceId = action.payload;
    },
  },
});

export const callQueueSelector = (state: RootState) =>
  state.callQueue.callQueue;
export const phoneCallQueueSelector = (state: RootState) =>
  state.callQueue.phoneCallQueue;
export const counterCallQueueSelector = (state: RootState) =>
  state.callQueue.counterCallQueue;
export const currentTopCallSelector = (state: RootState) =>
  state.callQueue.currentTopCall;
export const currentTopPhoneCallSelector = (state: RootState) =>
  state.callQueue.currentTopPhoneCall;
export const currentTopCounterCallSelector = (state: RootState) =>
  state.callQueue.currentTopCounterCall;
export const timeLastUpdatedSelector = (state: RootState) =>
  state.callQueue.timeLastUpdated;
export const sequenceIdSelector = (state: RootState) =>
  state.callQueue.sequenceId;

export const {
  updateCallQueue,
  updatePhoneCallQueue,
  updateCounterCallQueue,
  updateCurrentTopCall,
  updateCurrentTopPhoneCall,
  updateCurrentTopCounterCall,
  updateTimeLastUpdated,
  updateSequenceId,
} = callQueueSlice.actions;

export default callQueueSlice.reducer;
