import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { Button } from "componentsV2/Button";
import { LoadingIndicator } from "componentsV2/LoadingIndicator";

type DropdownAction = {
  label: string;
  onClick: () => void;
};

export interface ButtonDropdownProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  Icon?: React.ElementType;
  loading?: boolean;
  dropdownActions?: DropdownAction[];
}

const getContainerClasses = (
  options?: Partial<{ loading: boolean; disabled: boolean }>
) => {
  const baseClasses = "flex items-stretch rounded-md overflow-hidden";
  const variantClasses =
    "text-white shadow-sm border bg-navy-900 border-navy-900 hover:bg-navy-700 focus-within:outline-navy-900 focus-within:outline focus-within:outline-2 focus-within:outline-offset-2";
  const disabledClasses = "opacity-60 cursor-not-allowed";
  return cn(baseClasses, variantClasses, {
    // Ignore disabled styles when disabled due to loading
    [disabledClasses]: options?.disabled && !options?.loading,
  });
};

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  children,
  className,
  Icon,
  loading,
  disabled,
  dropdownActions,
  ...buttonProps
}) => {
  const renderPrimaryButton = (
    children?: React.ReactNode,
    Icon?: React.ElementType,
    loading?: boolean
  ) => {
    const hasLabel = !!children;
    const hasIcon = !!Icon;
    const offsetIcon = hasIcon && hasLabel;
    const loadingReplacesLabel = loading && !hasIcon;

    return (
      <button
        className="py-2 px-3 text-sm leading-5 font-medium text-inherit inline-flex items-center gap-x-2 relative"
        disabled={disabled || loading}
        {...buttonProps}
      >
        {hasIcon && (
          <Icon
            className={cn("h-5 w-5", {
              "-ml-0.5": offsetIcon,
              hidden: loading,
            })}
            aria-hidden="true"
          />
        )}
        {loading && (
          <LoadingIndicator
            className={cn({
              "-ml-0.5": offsetIcon,
              "absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2":
                loadingReplacesLabel,
            })}
          />
        )}
        {hasLabel && (
          <span className={cn({ "opacity-0": loadingReplacesLabel })}>
            {children}
          </span>
        )}
      </button>
    );
  };

  const renderDropdown = () => {
    return (
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-full absolute left-0 right-0 z-20 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden focus:outline-none">
          {dropdownActions?.map(({ label, onClick }, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <button
                  type="button"
                  onClick={onClick}
                  className={cn(
                    "block px-4 py-2 text-sm w-full text-left",
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  )}
                >
                  {label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    );
  };

  // Return regular button if dropdownActions not provided
  if (!dropdownActions?.length) {
    return (
      <Button
        className={className}
        Icon={Icon}
        loading={loading}
        disabled={disabled}
        {...buttonProps}
      >
        {children}
      </Button>
    );
  }

  return (
    <Menu as="div" className={cn("relative w-max", className)}>
      <div className={getContainerClasses({ loading, disabled })}>
        {renderPrimaryButton(children, Icon, loading)}
        <div className="py-2 opacity-60">
          <div className="bg-current w-px h-full" />
        </div>
        <Menu.Button
          className="px-3 py-2"
          disabled={disabled || loading}
          aria-label="Show additional actions"
        >
          <ChevronDownIcon
            className="h-5 w-5 text-inherit"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      {renderDropdown()}
    </Menu>
  );
};
