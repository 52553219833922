import { DocumentIcon } from "@heroicons/react/24/outline";
import React, { useId, useMemo, useRef, useState } from "react";
import { Button, ButtonVariant } from "componentsV2/Button";
import { ButtonDropdown } from "componentsV2/ButtonDropdown";
import { Select } from "componentsV2/Inputs";
import { Modal } from "componentsV2/Modal";
import { useSnackbar } from "modulesV2/Snackbar";
import { ReadyAlert } from "../ReadyAlert";
import { MOVE_IN_DOCS_VALUE } from "../../constants";
import { useDocumentModal } from "../../contexts/DocumentModalContext";
import { ModalState } from "../../types";
import {
  filterFacilityDocumentsForAdhocEsign,
  getFacilityDocumentDisplayName,
  isMoveInDocsValue,
} from "../../utils";
import { AxiosError } from "axios";

export const GenDocModal: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const formId = useId();
  const doneButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const { showErrorSnack } = useSnackbar();
  const {
    active,
    activeModal,
    setActiveModal,
    loading,
    tenantLedgers,
    activeLedgerId,
    facilityDocuments,
    selectedFacilityDocumentId,
    setSelectedFacilityDocumentId,
    handleClose,
    setActiveTenantEsign,
    generateTenantEsign,
  } = useDocumentModal();

  const activeLedgerUnitName =
    tenantLedgers.find(
      (tenantLedger) => tenantLedger.pmsLedgerId === activeLedgerId
    )?.unit.name ?? "Unknown";

  const submitDisabled = loading || submitting || !selectedFacilityDocumentId;

  const modalActions = (
    <>
      <Button
        type="button"
        variant={ButtonVariant.WHITE}
        className="mr-auto"
        onClick={() => {
          setActiveModal(ModalState.SendDoc);
        }}
      >
        Back
      </Button>
      <Button type="button" variant={ButtonVariant.WHITE} onClick={handleClose}>
        Cancel
      </Button>
      <ButtonDropdown
        form={formId}
        type="submit"
        disabled={submitDisabled}
        loading={submitting}
        dropdownActions={[
          {
            label: "Generate (No Cache)",
            onClick: () => generateEsign(true),
          },
        ]}
      >
        {isMoveInDocsValue(selectedFacilityDocumentId)
          ? "Generate Documents"
          : "Generate Document"}
      </ButtonDropdown>
    </>
  );

  const selectOptions = useMemo<{ label: string; value: string }[]>(
    () => [
      { label: "All move in documents", value: MOVE_IN_DOCS_VALUE },
      ...filterFacilityDocumentsForAdhocEsign(facilityDocuments).map((doc) => ({
        label: getFacilityDocumentDisplayName(doc),
        value: doc.id.toString(),
      })),
    ],
    [facilityDocuments]
  );

  const handleChangeSelect: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setSelectedFacilityDocumentId(e.target.value);
  };

  const generateEsign = async (regenerate?: boolean) => {
    if (submitDisabled) {
      return;
    }

    try {
      setSubmitting(true);
      const tenantEsign = await generateTenantEsign(
        selectedFacilityDocumentId,
        { regenerate }
      );
      setActiveTenantEsign(tenantEsign);
      setActiveModal(ModalState.SendGenDoc);
    } catch (e) {
      console.error(e);

      let errorMessage = "";
      if (
        e instanceof AxiosError &&
        e.response?.status === 400 &&
        e.response?.data?.message ===
          "Document does not have an esign signature"
      ) {
        errorMessage = "The selected document does not have an signature";
      } else if (isMoveInDocsValue(selectedFacilityDocumentId)) {
        errorMessage = "Unable to generate move in documents";
      } else {
        const docName =
          selectOptions.find(
            ({ value }) => value === selectedFacilityDocumentId
          )?.label || selectedFacilityDocumentId;
        errorMessage = `Unable to generate document: ${docName}`;
      }
      showErrorSnack(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    generateEsign();
  };

  return (
    <Modal
      active={active && activeModal === ModalState.GenDoc}
      handleClose={handleClose}
      title="Generate New Document"
      Icon={DocumentIcon}
      initialFocusRef={doneButtonRef}
      actions={modalActions}
      hideBackdrop
      allowInnerOverflow
    >
      <div className="mt-4 w-[540px] pb-2">
        <ReadyAlert />
        <div className="mt-4 space-y-4">
          <h2 className="text-base text-gray-900 font-semibold">
            Unit Number: {activeLedgerUnitName}
          </h2>
          <form id={formId} onSubmit={handleSubmitForm} className="max-w-xs">
            <Select
              label="Type"
              name="doc-type-select"
              options={selectOptions}
              value={selectedFacilityDocumentId}
              onChange={handleChangeSelect}
              placeholder="Select type"
              required
              disabled={loading}
            />
          </form>
        </div>
      </div>
    </Modal>
  );
};
