import { TaskAssignment, User } from "api/types";

type ClarityIdentity = {
  uid: string | number;
  properties?: {
    displayName?: string;
    email?: string;
    custom_field?: unknown;
  };
};

type ClarityTrackEvent = {
  name: string;
  properties: Record<string, unknown>;
};

export function useClarity() {
  const clarity = (action: string, ...args: (string | number | null)[]) => {
    if (typeof window.clarity !== "function") return false;
    window.clarity(action, ...args);
  };

  const clarityTrackEvent = (data: ClarityTrackEvent) => {
    clarity("event", data.name);

    if (typeof data.properties.taskId === "string") {
      clarity("set", "task_id", data.properties.taskId);
    }
    if (typeof data.properties.taskType === "string") {
      clarity("set", "task_type", data.properties.taskType);
    }
    if (typeof data.properties.taskAssignmentId === "string") {
      clarity("set", "task_assignment_id", data.properties.taskAssignmentId);
    }
    if (typeof data.properties.timeCreated === "string") {
      clarity("set", "time_created", data.properties.timeCreated);
    }
  };

  const claritySetIdentity = (data: ClarityIdentity) => {
    if (typeof window.clarity !== "function") return false;
    window.clarity("consent");
    window.clarity(
      "identify",
      data.properties?.email || "unknown-user",
      data.uid,
      "vmos",
      data.properties?.displayName || null
    );
  };

  const claritySetAgentIdentity = (agent: User) => {
    claritySetIdentity({
      uid: agent.id,
      properties: {
        displayName: `${agent.firstName} ${agent.lastName}`,
        email: agent.email,
      },
    });
  };

  const agentProperties = (agent: User) => {
    return {
      timestamp: new Date().toISOString(),
      agentName: `${agent.firstName} ${agent.lastName}`,
      agentId: agent.id,
      agentEmail: agent.email,
      agentUsername: agent.username,
      currentUrl: window.location.href,
      org: agent.organization,
      orgId: agent.organizationId,
    };
  };

  const claritySetAgentStatusAway = (agent: User) => {
    clarityTrackEvent({
      name: "Agent Status Away",
      properties: agentProperties(agent),
    });
  };

  const claritySetAgentStatusAvailable = (agent: User) => {
    clarityTrackEvent({
      name: "Agent Status Available",
      properties: agentProperties(agent),
    });
  };

  const claritySetAgentAcceptTask = (
    agent: User,
    taskAssignment: TaskAssignment
  ) => {
    clarityTrackEvent({
      name: "Agent Accept Task",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
      },
    });
  };

  const claritySetAgentPassTask = (
    agent: User,
    taskAssignment: TaskAssignment
  ) => {
    clarityTrackEvent({
      name: "Agent Pass Task",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
      },
    });
  };

  const claritySetAgentAutoPassTask = (
    agent: User,
    taskAssignment: TaskAssignment
  ) => {
    clarityTrackEvent({
      name: "Agent Auto Pass Task",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
      },
    });
  };

  const claritySetAgentDismissTask = (
    agent: User,
    taskAssignment: TaskAssignment
  ) => {
    clarityTrackEvent({
      name: "Agent Dismiss Task",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
      },
    });
  };

  const claritySetAgentCounterAlert = (
    agent: User,
    taskAssignment: TaskAssignment
  ) => {
    clarityTrackEvent({
      name: "Agent Received Counter Alert",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
        timeCreated: taskAssignment.timeCreated,
      },
    });
  };

  return {
    claritySetAgentIdentity,
    claritySetAgentStatusAway,
    claritySetAgentStatusAvailable,
    claritySetAgentAcceptTask,
    claritySetAgentPassTask,
    claritySetAgentDismissTask,
    claritySetAgentCounterAlert,
    claritySetAgentAutoPassTask,
  };
}
