import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getOrganizationAsyncTasks,
  patchAsyncTaskV2,
  patchAsyncTaskAssignment,
  postAsyncTaskAssignment,
} from "api/asyncTasks";
import {
  AsyncTask,
  AsyncTaskAssignment,
  AsyncTaskAssignmentType,
  CustomerInteractionResolution,
} from "api/types";
import { useSnackbar } from "components/Snackbar";
import { useDashboard } from "contexts/DashboardContext";
import { useDispatch, useSelector, useStore } from "hooks/redux";
import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";
import { useTwilio } from "modules/Twilio";
import { connectToOutgoingPhoneCall } from "services/connectCalls";
import { pusher, handlePusherResourceEvent } from "services/Pusher";
import { agentSelector } from "state/agentSlice";
import {
  asyncTasksCountSelector,
  updateAsyncTasksCount,
} from "state/asyncTasksSlice";
import { facilitiesSelector } from "state/facilitiesSlice";
import {
  tasksLocalStateSelector,
  updateTaskAssignmentLocalState,
} from "state/tasksSlice";
import {
  getAsyncTaskCustomer,
  getAsyncTaskResolutionKey,
  getTaskPhoneNumber,
} from "utils/asyncTask";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
import { isFacilityLiveOrPreleasing } from "utils/facility";
import { PAGE_SIZE } from "../constants";
import { useTasksReducer, TaskAction } from "../hooks/useTasksReducer";
import { ManualCallValues } from "../types";
import { calculateNewPage } from "../utils";

interface TasksContextValue {
  manualCallActive: boolean;
  manualCallModalOpen: boolean;
  page: number;
  pageLoading: boolean;
  pageCount: number;
  facilitiesOptions: { value: string; label: string }[];
  tasks: AsyncTask[];
  tasksLoading: Record<number, boolean>;
  incomingAsyncTasks: number[];
  setManualCallModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleStartManualCall: (values: ManualCallValues) => Promise<void>;
  handleEndManualCall: () => Promise<void>;
  fetchAsyncTasks: () => Promise<void>;
  setTaskLoading: (id: number, value: boolean) => void;
  hangUpTask: (task: AsyncTask) => Promise<void>;
  handleStartTask: (task: AsyncTask) => Promise<void>;
  handleDialInTask: (task: AsyncTask) => Promise<void>;
  handleFollowUpTask: (task: AsyncTask, notes?: string) => Promise<boolean>;
  handleCompleteTask: (
    task: AsyncTask,
    isUsingOrgResolution: boolean,
    resolution: string
  ) => Promise<void>;
}

const initialValue = {
  manualCallActive: false,
  manualCallModalOpen: false,
  page: 1,
  pageLoading: false,
  pageCount: 1,
  facilitiesOptions: [],
  tasks: [],
  tasksLoading: {},
  incomingAsyncTasks: [],
  setManualCallModalOpen: emptyFn,
  setPage: emptyFn,
  handleStartManualCall: asyncEmptyFn,
  handleEndManualCall: asyncEmptyFn,
  fetchAsyncTasks: asyncEmptyFn,
  setTaskLoading: emptyFn,
  hangUpTask: asyncEmptyFn,
  handleStartTask: asyncEmptyFn,
  handleDialInTask: asyncEmptyFn,
  handleFollowUpTask: async () => true,
  handleCompleteTask: asyncEmptyFn,
};

const TasksContext = createContext<TasksContextValue>(initialValue);

interface TasksProviderProps {
  children: React.ReactNode;
}

export const TasksProvider: React.FC<TasksProviderProps> = ({ children }) => {
  const [manualCallModalOpen, setManualCallModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [tasksLoading, setTasksLoading] = useState<Record<number, boolean>>({});

  const [tasks, dispatchTasks] = useTasksReducer();
  const dispatchRedux = useDispatch();

  const agent = useSelector(agentSelector);
  const asyncTasksCount = useSelector(asyncTasksCountSelector);
  const facilities = useSelector(facilitiesSelector);
  const { dialedInAsyncTaskId, dialedInPhoneNumber } = useSelector(
    tasksLocalStateSelector
  );
  const manualCallActive = !!dialedInPhoneNumber;
  const facilitiesOptions = facilities
    .filter(isFacilityLiveOrPreleasing)
    .map(({ id, title, altTitle }) => ({
      value: `${id}`,
      label: altTitle ?? title,
    }));

  const { showSuccessSnack, showErrorSnack } = useSnackbar();
  const { disconnectPhoneCall } = useTwilio();
  const pageCount = Math.ceil(asyncTasksCount / PAGE_SIZE);
  const store = useStore();
  const snacks = useSnackbar();
  const { incomingAsyncTasks, resetIncomingAsyncTasks } = useDashboard();
  const { asyncTaskFilters } = useAsyncTaskFilters();

  const fetchAsyncTasks = async () => {
    try {
      setPageLoading(true);
      const offset = (page - 1) * PAGE_SIZE;
      const res = await getOrganizationAsyncTasks(
        PAGE_SIZE,
        offset,
        asyncTaskFilters
      );
      dispatchTasks({
        type: TaskAction.Upload,
        payload: res.data.results,
      });
      dispatchRedux(updateAsyncTasksCount(res.data.count));
      resetIncomingAsyncTasks();
    } catch (e) {
      showErrorSnack("An error occurred while fetching tasks.");
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(
    function fetchAsyncTasksWhenPageOrFiltersUpdated() {
      fetchAsyncTasks();
    },
    [page, asyncTaskFilters]
  );

  useEffect(
    function redirectToPageOneWhenFiltersUpdated() {
      if (page !== 1) {
        setPage(1);
      }
    },
    [asyncTaskFilters]
  );

  /**
   * Subscribes to pusher channels
   * */
  useEffect(
    function subscribeToPusherChannels() {
      // Prefixed channel names
      const pushChannelPrefix = agent.organization?.pushChannelPrefix ?? "";
      if (!pushChannelPrefix) {
        return;
      }
      const ataCreatedChannelName =
        pushChannelPrefix + "AsyncTaskAssignment_created";
      const ataUpdatedChannelName =
        pushChannelPrefix + "AsyncTaskAssignment_updated";
      const asyncTaskChannelNames = [
        "LeadFollowUpTask_updated",
        "MissedCallFollowUpTask_updated",
        "MessageTask_updated",
      ].map((name) => pushChannelPrefix + name);

      // Subscribe to channels
      const ataCreatedChannels = pusher.subscribe(ataCreatedChannelName);
      const ataUpdatedChannels = pusher.subscribe(ataUpdatedChannelName);
      const asyncTaskChannelsArray = asyncTaskChannelNames.map((name) =>
        pusher.subscribe(name)
      );

      // Event handlers
      const handleAsyncTaskAssignmentCreated = (
        asyncTaskAssignment: AsyncTaskAssignment
      ) => {
        dispatchTasks({
          type: TaskAction.AddTaskAssignment,
          payload: {
            id: asyncTaskAssignment.asyncTaskId,
            taskAssignment: asyncTaskAssignment,
          },
        });
      };

      const handleAsyncTaskAssignmentUpdated = (
        asyncTaskAssignment: AsyncTaskAssignment
      ) => {
        dispatchTasks({
          type: TaskAction.PatchTaskAssignment,
          payload: {
            id: asyncTaskAssignment.asyncTaskId,
            taskAssignment: asyncTaskAssignment,
          },
        });
      };

      // Generic model_updated handler for AsyncTasks
      const handleAsyncTaskUpdated = (asyncTask: AsyncTask) => {
        dispatchTasks({
          type: TaskAction.UpdateTask,
          payload: asyncTask,
        });
      };

      // Bind event handlers
      ataCreatedChannels.forEach((ataCreatedChannel) => {
        ataCreatedChannel.bind("model_created", (event: unknown) =>
          handlePusherResourceEvent(event, handleAsyncTaskAssignmentCreated)
        );
      });
      ataUpdatedChannels.forEach((ataUpdatedChannel) => {
        ataUpdatedChannel.bind("model_updated", (event: unknown) =>
          handlePusherResourceEvent(event, handleAsyncTaskAssignmentUpdated)
        );
      });
      asyncTaskChannelsArray.forEach((asyncTaskChannels) => {
        asyncTaskChannels.forEach((channel) => {
          channel.bind("model_updated", (event: unknown) =>
            handlePusherResourceEvent(event, handleAsyncTaskUpdated)
          );
        });
      });

      return () => {
        // Unbind event handlers
        ataCreatedChannels.forEach((ataCreatedChannel) => {
          ataCreatedChannel.unbind("model_created");
        });
        ataUpdatedChannels.forEach((ataUpdatedChannel) => {
          ataUpdatedChannel.unbind("model_updated");
        });
        asyncTaskChannelsArray.forEach((asyncTaskChannels) => {
          asyncTaskChannels.forEach((channel) => {
            channel.unbind("model_updated");
          });
        });

        // Unsubscribe from channels
        pusher.unsubscribe(ataCreatedChannelName);
        pusher.unsubscribe(ataUpdatedChannelName);
        asyncTaskChannelNames.forEach((name) => {
          pusher.unsubscribe(name);
        });
      };
    },
    [agent.organization?.pushChannelPrefix]
  );

  // CallNumberModal/manual call functions
  const handleStartManualCall = async (values: ManualCallValues) => {
    try {
      await connectToOutgoingPhoneCall({
        phoneNumber: values.phoneNumber,
        store,
        snacks,
        facilityId: values.facilityId,
        userId: agent.id,
      });
    } catch (e) {
      if (e instanceof Error) {
        showErrorSnack(e.message);
      } else {
        showErrorSnack();
      }
    }
  };

  const handleEndManualCall = async () => {
    disconnectPhoneCall();
  };

  const setTaskLoading = (id: number, value: boolean) => {
    setTasksLoading((curr) => ({ ...curr, [id]: value }));
  };

  const hangUpTask = async (task: AsyncTask) => {
    // Because AsyncTask calls are direct calls to phone numbers instead of
    // conference calls, request to the backend to end the call is not necessary
    // Simply disconnect the browser with Twilio's putPhoneOnHold
    try {
      const isActiveAsyncCall = task.id === dialedInAsyncTaskId;
      if (isActiveAsyncCall) {
        await disconnectPhoneCall();
        dispatchRedux(updateTaskAssignmentLocalState({}));
      }
    } catch (e) {
      showErrorSnack("An error occurred while hanging up.");
    }
  };

  // TaskCard
  const handleStartTask = async (task: AsyncTask) => {
    try {
      setTaskLoading(task.id, true);
      await postAsyncTaskAssignment(task.id);
    } catch (e) {
      showErrorSnack(`An error occurred while starting task ${task.id}`);
    } finally {
      setTaskLoading(task.id, false);
    }
  };

  const handleDialInTask = async (task: AsyncTask) => {
    try {
      const phoneNumber = getTaskPhoneNumber(task);
      if (!phoneNumber) {
        throw new Error("No customer phone number found.");
      }
      const customer = getAsyncTaskCustomer(task);
      await connectToOutgoingPhoneCall({
        phoneNumber,
        store,
        snacks,
        asyncTaskId: task.id,
        facilityId: task.facilityId,
        userId: agent.id,
        tenantId: customer?.id,
      });
    } catch (e) {
      if (e instanceof Error) {
        showErrorSnack(e.message);
      } else {
        showErrorSnack("An error occurred while connecting to call");
      }
    }
  };

  const handleFollowUpTask = async (task: AsyncTask, notes: string = "") => {
    let patchAtaSuccess = false;
    try {
      setTaskLoading(task.id, true);
      await hangUpTask(task);
      const ata = task.asyncTaskAssignments;
      if (ata.length === 0) {
        return false;
      }
      await patchAsyncTaskAssignment(ata[ata.length - 1].id, {
        status: AsyncTaskAssignmentType.Revisit,
        notes,
      });

      const resolutionKey = getAsyncTaskResolutionKey(task);
      await patchAsyncTaskV2(task, {
        [resolutionKey]: CustomerInteractionResolution.NotSet,
      });
      patchAtaSuccess = true;
    } catch (e) {
      showErrorSnack("An error occurred while patching the task");
    } finally {
      setTaskLoading(task.id, false);
    }

    return patchAtaSuccess;
  };

  const handleCompleteTask = async (
    task: AsyncTask,
    isUsingOrgResolution: boolean,
    resolution: string
  ) => {
    if (!resolution) {
      showErrorSnack();
      return;
    }

    try {
      setTaskLoading(task.id, true);
      await hangUpTask(task);
      const ata = task.asyncTaskAssignments;
      if (ata.length === 0) {
        return;
      }
      await patchAsyncTaskAssignment(ata[ata.length - 1].id, {
        status: AsyncTaskAssignmentType.Completed,
      });

      const resolutionKey = getAsyncTaskResolutionKey(task);

      let taskPartial;
      if (!isUsingOrgResolution) {
        if (
          !Object.values<string>(CustomerInteractionResolution).includes(
            resolution
          )
        ) {
          throw new Error("Invalid resolution.");
        }

        taskPartial = {
          [resolutionKey]: resolution as CustomerInteractionResolution,
        };
      } else {
        taskPartial = { orgResolution: resolution };
      }

      await patchAsyncTaskV2(task, taskPartial);

      const newPage = calculateNewPage(asyncTasksCount, page, pageCount);
      if (page !== newPage) {
        // Changes page if completed only task on last page
        setPage(newPage);
      } else {
        // Otherwise, refresh page
        fetchAsyncTasks();
      }
      showSuccessSnack(`Completed task ${task.id}`);
    } catch (e) {
      showErrorSnack(`An error occurred while completing task ${task.id}`);
    } finally {
      setTaskLoading(task.id, false);
    }
  };

  return (
    <TasksContext.Provider
      value={{
        manualCallActive,
        manualCallModalOpen,
        page,
        pageLoading,
        pageCount,
        facilitiesOptions,
        tasks,
        tasksLoading,
        incomingAsyncTasks,
        setManualCallModalOpen,
        setPage,
        handleStartManualCall,
        handleEndManualCall,
        fetchAsyncTasks,
        setTaskLoading,
        hangUpTask,
        handleStartTask,
        handleDialInTask,
        handleFollowUpTask,
        handleCompleteTask,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = () => useContext(TasksContext);

export const MockTasksProvider = createMockContextProvider(
  TasksContext,
  initialValue
);
