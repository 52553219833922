import client from "api/client";
import { Paginated } from "api/types";
import { camelizeKeys } from "humps";

type ResourceEvent<ResourceType> = {
  resourceUrl: string;
  isPartial: boolean;
  payload: ResourceType;
  timeCreated: string;
};

export const handlePusherResourceEvent = async <ResourceType>(
  event: unknown,
  handler: (data: ResourceType, createdAt: string) => void
) => {
  const resourceEvent = camelizeKeys(event) as ResourceEvent<ResourceType>;
  let data;
  if (resourceEvent.isPartial) {
    const res = await client.get<ResourceType>(resourceEvent.resourceUrl);
    data = res.data;
  } else {
    data = resourceEvent.payload;
  }

  handler(data, resourceEvent.timeCreated);
};

export const handlePaginatedPusherResourceEvent = async <ResourceType>(
  event: unknown,
  handler: (data: ResourceType[], createdAt: string) => void,
  params: Record<string, unknown> = { limit: 100 }
) => {
  const resourceEvent = camelizeKeys(event) as ResourceEvent<ResourceType[]>;
  let data;
  if (resourceEvent.isPartial) {
    const res = await client.get<Paginated<ResourceType>>(
      resourceEvent.resourceUrl,
      { params }
    );
    data = res.data.results;
  } else {
    data = resourceEvent.payload;
  }
  handler(data, resourceEvent.timeCreated);
};
