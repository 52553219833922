import React, { useState } from "react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button, ButtonVariant } from "componentsV2/Button";

import { LabelledCheckbox } from "./LabelledCheckbox";
import {
  CheckboxChangeHandler,
  FormSubmitHandler,
  FilterGroupItem,
  HandleUpdateItems,
} from "../types";

export const testIds = {
  outer: "panel-form-outer",
};

interface PanelFormProps {
  items: FilterGroupItem[];
  activeItems: string[];
  handleUpdateItems: HandleUpdateItems;
  closePanel: () => void;
  showFilterInput?: boolean;
}

const SELECT_ALL_NAME = "filter-group-select-all";

export const PanelForm: React.FC<PanelFormProps> = ({
  items,
  activeItems,
  handleUpdateItems,
  closePanel,
  showFilterInput,
}) => {
  const initialCheckedValues = items.reduce(
    (acc, { value }) => ({
      ...acc,
      [value]: activeItems.includes(value),
    }),
    {}
  );

  const [checkedItems, setCheckedItems] =
    useState<Record<string, boolean>>(initialCheckedValues);
  const [labelFilterInputValue, setLabelFilterInputValue] = useState("");

  const filteredItems = items.filter(
    ({ label }) =>
      !labelFilterInputValue ||
      label
        .toLocaleLowerCase()
        .includes(labelFilterInputValue.toLocaleLowerCase())
  );

  const selectAllChecked = filteredItems.every(
    ({ value }) => checkedItems[value]
  );

  const handleSelectAllChange: CheckboxChangeHandler = (e) => {
    const { checked } = e.currentTarget;
    setCheckedItems((curr) =>
      Object.keys(curr).reduce((acc, curr) => ({ ...acc, [curr]: checked }), {})
    );
  };

  const handleItemChange: CheckboxChangeHandler = (e) => {
    const { checked, value } = e.currentTarget;
    setCheckedItems((curr) => ({ ...curr, [value]: checked }));
  };

  const handleSubmit: FormSubmitHandler = (e) => {
    e.preventDefault();
    const selectedItems = filteredItems.reduce<string[]>(
      (acc, { value }) => (checkedItems[value] ? [...acc, value] : acc),
      []
    );
    handleUpdateItems(selectedItems);
    closePanel();
  };

  return (
    <form onSubmit={handleSubmit} data-testid={testIds.outer}>
      <div className="bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 min-w-[224px] divide-y divide-gray-100">
        <div className="p-4">
          <LabelledCheckbox
            name={SELECT_ALL_NAME}
            value="select-all"
            label="Select all"
            checked={selectAllChecked}
            onChange={handleSelectAllChange}
          />
        </div>
        {showFilterInput && (
          <div className="relative">
            <MagnifyingGlassIcon
              className="absolute top-1/2 -translate-y-1/2 left-4 h-4 w-4 text-gray-500"
              aria-hidden="true"
            />
            <input
              type="text"
              value={labelFilterInputValue}
              onChange={(e) => setLabelFilterInputValue(e.target.value)}
              placeholder="Type to search"
              className="rounded-md text-sm text-navy-900 placeholder:text-gray-500 border-none px-10 py-3 focus:ring-navy-700 focus:ring-2 focus:ring-inset"
            />
            {labelFilterInputValue.length > 0 && (
              <button
                type="button"
                aria-label="Clear search"
                onClick={() => setLabelFilterInputValue("")}
              >
                <XMarkIcon
                  className="absolute top-1/2 -translate-y-1/2 right-4 h-4 w-4 text-gray-500"
                  aria-hidden="true"
                />
              </button>
            )}
          </div>
        )}
        <ul
          className="grid gap-4 p-4 max-h-[calc(100vh-360px)] overflow-auto"
          role="listbox"
        >
          {filteredItems.map((item) => (
            <li key={item.value}>
              <LabelledCheckbox
                name={item.value}
                value={item.value}
                label={item.label}
                checked={checkedItems[item.value]}
                onChange={handleItemChange}
              />
            </li>
          ))}
        </ul>
        <div className="flex gap-2 p-4">
          <Button type="submit">Apply</Button>
          <Button
            type="button"
            onClick={closePanel}
            variant={ButtonVariant.WHITE}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};
