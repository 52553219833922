import { getOrganizationAsyncTasks } from "api/asyncTasks";
import { AsyncTaskFilters } from "api/types";
import { updateAsyncTasksCount } from "state/asyncTasksSlice";
import { AppDispatch } from "store";

export const fetchAsyncTasksCount =
  (filters: Partial<AsyncTaskFilters> = {}) =>
  async (dispatch: AppDispatch) => {
    const res = await getOrganizationAsyncTasks(1, 0, filters);
    dispatch(updateAsyncTasksCount(res.data.count));
    return res.data.count;
  };
